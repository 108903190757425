.body-single-course
{
    background-color: #EAE2D8;
    min-height: 100vh;

    .container-fluid
    {
        > .header-holder
        {
            .logo-holder
            {
                text-align: center;
                float: none;
                margin-bottom: 30px;
                
                .title-logo
                {
                    margin-bottom: 0px;

                    .logo
                    {
                        width: 168px;
                        height: 91px;
                    }
                }
            }
    
            .exit-holder
            {
                margin-bottom: 30px;
                text-align: right;
                float: none;

                a
                {
                    color: #000;
                    font-size: 15px;
                    line-height: 20px;

                    i
                    {
                        font-size: 16px;
                        vertical-align: middle;
                        margin-right: 6px;
                        position: relative;
                        top: -2px;
                    }
                }
            }
        }

        > .video-holder
        {
            iframe
            {
                margin-bottom: 30px;
                height: 250px;
            }

            .descr-video
            {
                > h2
                {
                    font-size: 28px;
                    color: #000;
                    line-height: 38px;
                    margin: 0 0 15px;
                }

                > p
                {
                    color: #000;
                    font-size: 15px;
                    line-height: 20px;
                }
            }
        }

        > .sidebar-course-holder
        {
            > .sidebar-item
            {
                margin-bottom: 10px;

                > .sidebar-item-header
                {
                    margin: 0;
                    color: #000;
                    font-size: 18px;
                    line-height: 24px;
                    padding: 10px 0;
                    border-bottom: 1px solid #9B9B9B;
                    font-weight: 400;

                    i
                    {
                        margin-right: 3px;
                        font-size: 16px;
                    }
                }
                
                > .sidebar-item-content
                {
                    padding: 10px 0 10px;

                    .list-content
                    {
                        .list-item
                        {
                            font-size: 14px;
                            color: #4A4A4A;
                            line-height: 29px;

                            a
                            {
                                color: #4A4A4A;
                            }

                            i
                            {
                                font-size: 13px;
                            }

                            .time
                            {
                                float: none;
                                font-weight: 700;
                            }

                            &.list-item-tip
                            {
                                > p
                                {
                                    font-size: 12px;
                                    line-height: 17px;
                                    color: #4A4A4A;
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media screen
        {
            @media (min-width: 768px)
            {
                > .header-holder
                {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row-reverse;
                    margin: 0px 0 30px;
                    padding-top: 15px;

                    &:before,
                    &:after
                    {
                        display: none;
                    }

                    .logo-holder
                    {
                        margin: 0;
                        text-align: left;
                    }

                    .exit-holder
                    {
                        margin: 0;
                    }
                }

                > .video-holder
                {
                    iframe
                    {
                        height: 425px;
                    }
                }
            }

            @media (min-width: 1024px)
            {
                display: flex;
                flex-wrap: wrap;

                > .header-holder
                {
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                > .video-holder
                {
                    flex: 0 0 73%;
                    max-width: 73%;
                    margin-top: 0px;
                    padding-right: 30px;

                    iframe
                    {
                        height: 430px;
                    }

                    .descr-video
                    {
                        float: none;
                        width: 670px;
                    }
                }

                > .sidebar-course-holder
                {
                    flex: 0 0 27%;
                    max-width: 27%;

                    > .sidebar-item
                    {
                        &:first-child
                        {
                            > .sidebar-item-header
                            {
                                padding-top: 0px;
                            }
                        }
                    }
                }
            }

            @media (min-width: 1280px)
            {
                display: flex;
                flex-wrap: wrap;

                > .video-holder
                {
                    flex: 0 0 900px;
                    max-width: 900px;

                    iframe
                    {
                        height: 430px;
                    }
                }

                > .sidebar-course-holder
                {
                    flex: 0 0 270px;
                    max-width: 270px;
                }
            }
        }
    }
}