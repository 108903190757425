// @mixin reset()
// {
// 	margin: 0;
// 	padding: 0;
// 	vertical-align: baseline;
// }

// @mixin font-brand()
// {
// 	font-family: "Roboto Condensed", sans-serif;
// 	font-weight: 400;
// }

@mixin clearfix()
{
	content: '';
	clear: both;
	display: table;
}

@mixin font-alt()
{
	font-family: 'Exo', sans-serif;
	font-weight: 400;
}

@mixin container-fluid()
{
  	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
}

@mixin logo()
{
	display: inline-block;
	width: 212px;
	height: 109px;
	background: url('../../src/images/logo.png') no-repeat;
	background-size: contain;
	text-indent: -9999px;
}

@mixin palette($name, $color) {

	$negative: #000000;
	@if (lightness($color) > 50) {
		$negative: darken($color, 40)
	} @else {
		$negative: lighten($color, 40)
	}

	.palette-text-#{$name} { color: $color!important; }
	.palette-text-#{$name}-lighten { color: lighten($color, 20)!important; }
	.palette-text-#{$name}-darken { color: darken($color, 20)!important; }
	.palette-text-#{$name}-negative { color: $negative!important; }

	.palette-bg-#{$name} { background-color: $color!important; }
	.palette-bg-#{$name}-lighten { background-color: lighten($color, 20)!important }
	.palette-bg-#{$name}-darken { background-color: darken($color, 20)!important }
	.palette-bg-#{$name}-negative { background-color: $negative!important }

	.palette-border-#{$name} { border-color: $color!important }
	.palette-border-#{$name}-lighten { border-color: lighten($color, 20)!important }
	.palette-border-#{$name}-darken { border-color: darken($color, 20)!important }
	.palette-border-#{$name}-negative { border-color: $negative!important }

}