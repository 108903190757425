.container-fluid
{
	@include container-fluid;
}

.content-holder
{
	position: relative;
}

.renewal-holder
{
	margin-bottom: 30px;
}

@media screen and (min-width: 1200px) {
	.container-fluid
	{
		max-width: 1200px;
	}
}


@include palette("primary", $main-color);
@include palette("secondary", $second-color);