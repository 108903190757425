body {
  //LOGIN
  .login-container {
    background-image: url("../../images/welearn/bg-login.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .content-login {
      background-color: white;
      padding: 30px 20px;
      border-radius: 10px;
      .image-holder {
        margin: 0 0 30px;
      }
      .login-form-holder {
        width: unset;
      }
      .login-form-holder > form .form-group {
        margin-bottom: 30px;
        .btn {
          background-color: #1ea1db;
          border-color: #1ea1db;
          border-radius: 10px !important;
          font-weight: 400;
          &:hover {
            background-color: transparent;
            color: #1ea1db;
          }
        }
        .form-control {
          border: 1px solid #1ea1db;
          border-radius: 10px;
          color: #1ea1db;
          padding: 14px 30px;
          &::placeholder {
            color: #96a1a6;
          }
        }
        .label-control {
          font-size: 14px;
          line-height: 19px;
          margin-left: 10px;
          color: #1ea1db;
        }
        .forgot-password {
          font-size: 12px;
          width: 100%;
          line-height: 17px;
          color: #1ea1db;
          display: block;
          text-align: center;
        }
		.first-access {
			font-size: 16px;
		}
		.register {
			font-size: 14px;
			width: 100%;
			line-height: 17px;
			color: #1ea1db;
			text-align: center;
			text-decoration: underline;
		}
      }
      @media screen and(min-width: 768px) {
        padding: 60px 136px 60px 106px;
      }
    }
  }
  //END LOGIN

  //HEADER
  &.ead-page {
    .admin-actions {
      background: #0b4873;
    }
    .header-holder {
      background-color: white !important;
      .header-elements {
        .top-bar {
          height: unset;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 19px 0;
          .logo-holder {
            position: relative;
            left: 0;
            top: 0;
            min-width: 264px;
            margin-top: 0;
            margin-bottom: 20px;

            .logo-img {
              width: 100%;
              margin-bottom: 0;
              height: unset;
              .logo {
                width: 100%;
                height: 52px;
                background: url("../../images/welearn/logo-header.png")
                  no-repeat;
                background-size: contain;
              }
            }
          }
          .search-area {
            min-width: 270px;
            margin-bottom: 30px;
            position: relative;
            left: 0;
            top: 0;
            z-index: 1000;

            .searchbox {
              > .input-group.custom-search-form {
                input {
                  border: unset;
                  border-bottom: 1px solid #1ea1db !important;
                  margin-bottom: 2px;
                  color: #1ea1db !important;
                }
              }
              .search-results {
                position: absolute;
                top: 100%;
              }
            }
            .search-icon {
              color: #1ea1db !important;
            }
          }
          .name-holder {
            position: relative;
            top: 0;
            right: 0;
            .text {
              color: #808080 !important;
              font-weight: 400;
              line-height: 17px;
              font-size: 12px;
            }
          }
          @media screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
            .search-area {
              margin-bottom: 0;
            }
            .logo-holder {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  //END HEADER

  //FOOTER
  .footer {
    background-color: white;
    .container > div.footer-main-logo {
      flex: 0 0 100%;
      max-width: 100%;
      flex-direction: row;
      display: flex;
    }
  }
  .links-footer {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
  }
  .links-foote-link {
      margin-top: 8px;
  }
  //END FOOTER

  //SINGLE COURSE
  .body-single-course {
    background-color: #3d3e40;
    padding: 10px 0 0;
    min-height: calc(100vh - 120px);
    .content-course{
      display: flex;
      flex-direction: column;
      .sidebar-course-holder{
        border-radius: 3px;
        padding: 0 20px;
        background-color: #fff;
        .sidebar-item{
          .sidebar-item-header{
            border-bottom: 1px solid #e0e0e0;
            padding-bottom: 10px;
          }
          .sidebar-item-content{
            padding: 10px 0;
          }
        }
      }
      @media screen and (min-width:1024px) {
        margin-top: 30px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .descr-video{
          width: 64%;
        }
        .sidebar-course-holder{
          width: 36%;
        }

      }
    }
  }
  //END SINGLE COURSE

  //CONTENT
  .training-category {
    .category-title {
      position: relative;
      margin-bottom: 42px;
      z-index: 1;
      font-size: 18px;
      line-height: 24px;
      width: fit-content;
      color: white;
      padding: 2px 125px 2px 15px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 2px;
      background-color: #1ea1db;
      border-radius: 10px;
      &:before {
        position: absolute;
        left: -26px;
        z-index: -1;
        height: 59.5px;
        display: block;
        top: -12px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 80px;
        background-image: url("../../images/welearn/before-title.png");
      }
      @media screen and (min-width: 1024px) {
        margin-left: 26px;
        &:before{
          content: '';
        }
      }
    }
  }
}
