@charset "UTF-8";
* {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

.owl-item a {
  margin: 0 !important;
}

.form-control:focus {
  border-color: #e0221f;
}

.cover-header {
  background-color: #fafafa;
}

.expiry-content {
  margin-bottom: -30px;
}

.reactive-link:hover {
  text-decoration: underline;
}

.ui-droppable-hover {
  background-color: #ddd !important;
}

.form-ead label {
  color: #ed1c24;
}

#contact-submit.disabled {
  cursor: wait;
}

.btn {
  border: 1px solid #fff;
  touch-action: none;
}

.btn.btn-comvc {
  background: #ff001e;
  color: #FFF;
}

a {
  cursor: pointer;
}

.btn-disabled-modal {
  color: #758697;
  background: #e8e8e8;
}

.btn-disabled {
  background: #8bc34a;
}

.modal .modal-content .modal-header .modal-info .panel-title {
  font-size: 13px;
  font-weight: 600;
  color: #2b425b;
}

.text-success {
  color: #8bc34a !important;
}

.text-warning {
  color: #ff9902 !important;
}

.text-danger {
  color: #e0221f !important;
}

.body-single-course {
  background-color: #000;
}

.answered,
.answered .item-connect {
  border-color: #8bc34a !important;
}

.answered {
  border-right: 0 !important;
  border-radius: 3px 0 0 3px !important;
}

.answered .connect-aux {
  display: none;
}

.answered .answer-connect {
  border-left: none;
  border-radius: 0 3px 3px 0;
}

.text {
  color: #2b425b;
}

.btn.disabled,
.btn[disabled] {
  opacity: .2;
}

.badge {
  font-size: 13px;
  font-weight: normal;
  line-height: 14px;
  width: 21px;
  height: 21px;
  padding: 3px 0;
  text-align: center;
  border-radius: 50%;
}

.panel-site .panel-title {
  color: #2b425b;
}

.info-user-holder {
  width: auto;
}

.panel {
  border-radius: 3px !important;
}

.panel-body {
  padding: 20px;
}

.btn {
  border-radius: 3px !important;
}

.list-group-item.vertical {
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-group-item.vertical .banner-embed {
  margin-left: 10px;
}

.info-user-holder .panel {
  padding: 5px 20px;
  background: #e0221f;
}

.info-user-holder .panel .name-user {
  color: #fff;
}

.info-user-holder > div > div > span {
  padding: 15px;
  border: 1px solid #fff;
  background: url("../../images/8.png") no-repeat;
  background-size: cover;
}

.img-course {
  position: relative;
  display: block;
  width: 100%;
  height: 200px;
  margin-bottom: 30px;
  border-radius: 5px;
  background: url("../../images/card-img.png") no-repeat;
  background-size: cover;
}

.panel-info-course > .panel-body {
  position: relative;
  padding: 0;
}

.panel-info-course > .panel-body .descr-course {
  margin-bottom: 20px;
  padding: 15px 20px 15px;
  max-height: 190px;
  word-break: break-word;
  overflow: auto;
  border-bottom: 1px solid #e7ecf3;
}

.panel-info-course > .panel-body .info-course {
  padding: 0 20px 25px;
}

.panel-info-course > .panel-body .info-course > p {
  margin-bottom: 20px;
  color: #2b425b;
}

.panel-info-course > .panel-body .info-course > p:last-child {
  margin-bottom: 0;
}

.panel-info-course > .panel-body .info-course > p i {
  color: #000;
}

.pre-test-holder {
  clear: both;
}

.pre-test-holder,
.final-evaluation-holder,
.item-course-holder {
  margin-bottom: 15px;
}

.panel-icon {
  position: relative;
}

.panel .btn > i {
  margin-right: 9px;
  vertical-align: middle;
}

.panel-icon .icon-holder {
  line-height: 24px;
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: -25px;
  left: 0;
  display: block;
  width: 51px;
  height: 25px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #8bc34a;
  border-top: none;
  border-radius: 0 0 25px 25px;
  background: #fff;
}

.panel-icon:before {
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: -2px;
  left: 0;
  display: block;
  width: 49px;
  height: 3px;
  margin: 0 auto;
  content: '';
  background: #fff;
}

.last-panel:before {
  display: none;
}

.panel-icon .icon-holder-danger {
  border: 1px solid #e0221f;
}

.panel-icon .warning-icon {
  border-color: #ffa726;
}

.panel-icon .warning-icon i {
  color: #ffa726;
}

.panel-pre-test .btn {
  margin-top: 30px;
}

.success-icon > i {
  color: #8bc34a;
}

.panel-icon .danger-icon {
  border: 1px solid #e0221f;
}

.panel-item-course .panel-title {
  padding: 0;
}

.panel-item-course .img-course {
  margin-bottom: 5px;
}

.panel-item-course .info-course > p {
  margin-bottom: 40px;
}

.img-course .progress {
  position: absolute;
  bottom: 0;
  width: 99.9%;
  height: 8px;
  margin-bottom: 0;
  border-radius: 0 0 5px 5px;
}

.panel-opacity .panel-title,
.panel-opacity .text-default,
.panel-opacity .btn-disabled {
  opacity: .5;
}

.panel-opacity > .panel-body > .text-default {
  margin-bottom: 40px;
}

.text-mar-btm {
  margin-bottom: 20px;
}

.modal-info {
  margin-top: 15px;
}

.text-close-modal {
  display: inline-block;
  margin-bottom: 15px;
}

textarea.question-text {
  height: 190px;
}

.question-text {
  border: 1px solid #758697;
  border-radius: 3px;
}

.flexbox-area {
  display: flex;
  justify-content: space-between;
}

.progress-exercise {
  line-height: 30px;
}

.progress-exercise > p {
  margin-bottom: 0;
}

.answers-item {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  transition: all .4s ease-in-out;
  border: 1px solid #758697;
  border-radius: 3px;
}

.drop-item {
  position: relative;
  display: inline-block;
  width: 138px;
  height: 20px;
  margin: 0 5px;
  transition: all .4s ease-in-out;
  text-align: center;
  vertical-align: middle;
  border: 1px dashed #758697;
  border-radius: 3px;
}

.drop-item-complete {
  width: auto;
  height: auto;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.drag-holder {
  height: auto;
  min-height: 40px;
  margin-top: 35px;
  text-align: center;
}

.drag-holder > .drag-item {
  display: block;
  margin: 0 5px 20px 5px;
  padding: 10px;
  color: #2b425b;
  border: 1px solid #758697;
  border-radius: 3px;
}

body.course-page {
  background: #000;
}

.item-connect {
  position: relative;
  margin-bottom: 30px;
  padding: 10px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  border: 1px solid #758697;
  border-radius: 3px;
}

.question-connect {
  padding-bottom: 30px;
}

.connect-aux {
  display: inline-block;
  width: 40px;
  height: 20px;
  border: 1px solid #758697;
}

.connect-bot {
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: -1px;
  left: 0;
  margin: 0 auto;
  border-bottom: 0;
  border-radius: 25px 25px 0 0;
}

.connect-top {
  position: absolute;
  z-index: 3;
  top: -20px;
  right: 0;
  left: 0;
  margin: 0 auto;
  border-bottom: 0;
  border-radius: 25px 25px 0 0;
}

.white-space {
  position: absolute;
  bottom: -1px;
  display: block;
  width: 38px;
  height: 4px;
  background: #fff;
}

.title-area-exercise {
  margin-bottom: 30px;
}

.question-simple input[type=number]::-webkit-inner-spin-button,
.question-simple input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.question-simple a {
  position: relative;
  display: block;
}

.question-simple .question-number {
  line-height: 40px;
}

.question-simple .question-number, .question-simple .answer {
  width: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  font-size: 24px;
}

.question-simple .answer {
  left: -7px;
  margin-right: 5px;
  border: 1px solid;
  border-radius: 3px;
}

.answer-connect {
  margin-left: 30px;
}

.your-answer {
  padding: 20px;
  border-right: 1px solid #eeeeee;
}

.y-answer-title {
  margin-bottom: 28px;
  font-size: 16px;
  font-weight: bold;
}

.feedback {
  padding: 20px;
  padding-left: 20px !important;
}

.feedback-response {
  font-size: 28px;
  margin-bottom: 10px;
  text-align: center;
}

.question-connect-active {
  padding-bottom: 0;
}

.answer-connect-inactive {
  display: none;
}

.question-connect-active .answer-connect {
  margin: 20px 0;
}

.question-connect-active .connect-aux.connect-bot {
  display: none;
}

.question-connect-active .answer-connect .connect-aux.connect-top {
  display: none;
}

.modal {
  overflow-y: scroll;
}

.body-single-course .header-holder .logo-holder {
  float: left;
}

.body-single-course .header-holder .exit-holder {
  float: right;
  margin-top: 10px;
}

.body-single-course .header-holder .logo-holder .title-logo {
  margin-top: 0;
}

.ead-page .header-holder {
  background-color: #ecf0f5;
}

.ead-page.course-page .header-holder {
  background-color: transparent;
}

.header-holder .header-elements .logo-holder {
  display: inline-block;
}

.header-holder .header-elements {
  padding-bottom: 20px;
}

.header-holder .header-elements .top-bar {
  margin: 10px -15px 30px;
  height: 43px;
  margin-bottom: 0;
}

.ead-page .header-holder .name-holder .text {
  color: #5B422E;
}

.header-holder .menu-area {
  margin: 0 auto;
  background: #E5E5E5;
  padding: 10px 0;
  padding-top: 30px;
}

.body-single-course .header-holder .logo-holder .title-logo .logo {
  display: inline-block;
  width: 140px;
  height: 40px;
  text-indent: -9999px;
  background: url("../../images/logo.png") no-repeat;
  background-size: cover;
}

.body-single-course .header-holder .logo-holder .title-logo .logo.logo-alt {
  background: url("../../images/logo-normal.png") no-repeat;
  background-size: contain;
}

.back-index {
  font-size: 16px;
  color: #fff;
}

.back-index > i {
  font-size: 22px;
  vertical-align: sub;
}

.panel-accordion {
  margin-bottom: 0;
}

.panel-accordion:last-child {
  margin-bottom: 0;
}

.icon-accordion > i {
  font-size: 22px;
}

.gradient-circle {
  float: left;
}

.question-connect.active {
  border: 1px solid #8bc34a;
}

.circle-warning .icon-warning {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff9902+0,f2d000+100 */
  background: #ff9902;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ff9902 0%, #f2d000 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ff9902 0%, #f2d000 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ff9902 0%, #f2d000 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  -webkit-background-clip: text;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9902', endColorstr='#f2d000',GradientType=0 );
  /* IE6-9 */
  -webkit-text-fill-color: transparent;
}

.circle-success .icon-success {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8bc34a+0,d5ff00+100 */
  background: #8bc34a;
  /* Old browsers */
  background: -moz-linear-gradient(top, #8bc34a 0%, #d5ff00 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #8bc34a 0%, #d5ff00 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #8bc34a 0%, #d5ff00 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  -webkit-background-clip: text;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bc34a', endColorstr='#d5ff00',GradientType=0 );
  /* IE6-9 */
  -webkit-text-fill-color: transparent;
}

.icon-purple > i {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ef5350+0,ff0077+100 */
  background: #ef5350;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ef5350 0%, #ff0077 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ef5350 0%, #ff0077 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ef5350 0%, #ff0077 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  -webkit-background-clip: text;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef5350', endColorstr='#ff0077',GradientType=0 );
  /* IE6-9 */
  -webkit-text-fill-color: transparent;
}

.icon-primary > i {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#30c3fb+0,8400ff+100 */
  background: #30c3fb;
  /* Old browsers */
  background: -moz-linear-gradient(top, #30c3fb 0%, #8400ff 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #30c3fb 0%, #8400ff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #30c3fb 0%, #8400ff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  -webkit-background-clip: text;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30c3fb', endColorstr='#8400ff',GradientType=0 );
  /* IE6-9 */
  -webkit-text-fill-color: transparent;
}

.icon-caps > i {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ab46bc+0,ff008d+100 */
  background: #ab46bc;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ab46bc 0%, #ff008d 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ab46bc 0%, #ff008d 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ab46bc 0%, #ff008d 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  -webkit-background-clip: text;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ab46bc', endColorstr='#ff008d',GradientType=0 );
  /* IE6-9 */
  -webkit-text-fill-color: transparent;
}

.panel-group > div + div > .panel {
  margin-left: 0;
}

.text-alert {
  color: #ff9902;
}

.panel.collapse {
  border: 0;
}

.text-hiper {
  color: #e0221f;
}

.text-files {
  color: #1690f3;
}

.text-caps {
  color: #ab46bc;
}

.panel-accordion .panel-heading .text-center .accordion-active {
  background: #fff;
}

.accordion-item > span {
  line-height: 29px;
  float: left;
  margin-left: 20px;
}

.panel-accordion .panel-heading .panel-title > a {
  width: 100%;
  height: 50px;
  padding: 10px 15px;
}

.panel-accordion > .panel {
  border-radius: 0 0 5px 5px !important;
}

.video-holder > iframe {
  width: 100%;
  height: auto;
}

.video-holder > iframe.scorm-course {
  height: 220px;
}

.panel-accordion .panel .panel-body {
  padding-top: 5px;
}

.panel-accordion .panel .panel-body .panel-heading {
  margin: 0 -20px 20px;
  padding: 0 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.panel-accordion .panel .panel-body .text-panel {
  margin-bottom: 20px;
}

.holder-control {
  display: block;
  margin-top: 0;
}

.position-panel .panel-body .text-right {
  text-align: start;
}

.position-panel .panel-body .text-right .btn {
  float: right;
}

.holder-control .btn-tips:first-child {
  float: left;
}

.holder-control .btn-tips:last-child {
  float: right;
}

.holder-control .btn-tips {
  padding: 6px 7px;
}

.holder-control .btn-tips > i {
  margin-right: 0;
}

.hipervideos-list {
  margin: 20px 0 0;
}

.archive-list {
  margin: 20px 0 0;
}

.hipervideos-list .hipervideo-item:last-child {
  margin-bottom: 0;
}

.archive-list .archive-item:last-child {
  margin-bottom: 0;
}

.archive-item {
  display: block;
  margin-bottom: 20px;
  padding: 12px 6px;
  color: #2b425b;
  border: 1px solid #1690f3;
  border-radius: 3px;
}

.hipervideo-item {
  display: block;
  margin-bottom: 20px;
  padding: 12px 6px;
  color: #2b425b;
  border: 1px solid #e0221f;
  border-radius: 3px;
}

.text-hipervideo > i {
  font-size: 16px;
  vertical-align: sub;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ab46bc+0,ff008d+100 */
  background: #ab46bc;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ab46bc 0%, #ff008d 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ab46bc 0%, #ff008d 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ab46bc 0%, #ff008d 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  -webkit-background-clip: text;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ab46bc', endColorstr='#ff008d',GradientType=0 );
  /* IE6-9 */
  -webkit-text-fill-color: transparent;
}

.text-archive > i {
  font-size: 16px;
  vertical-align: sub;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#30c3fb+0,8400ff+100 */
  background: #30c3fb;
  /* Old browsers */
  background: -moz-linear-gradient(top, #30c3fb 0%, #8400ff 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #30c3fb 0%, #8400ff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #30c3fb 0%, #8400ff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  -webkit-background-clip: text;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30c3fb', endColorstr='#8400ff',GradientType=0 );
  /* IE6-9 */
  -webkit-text-fill-color: transparent;
}

.panel-accordion .panel-heading .panel-title {
  position: relative;
  z-index: 9;
  border-radius: 5px 5px 0 0;
}

.cap-item {
  display: block;
  margin-bottom: 10px;
}

.cap-item:last-child {
  margin-bottom: 0;
}

.cap-item .time {
  float: right;
}

.cap-item.block {
  color: #d1d9de;
}

.try-evaluate {
  display: block;
  margin-bottom: 10px;
}

.try-opacity {
  opacity: .3;
}

.accordion > .panel {
  border: 0;
}

.action-holder {
  margin-top: 20px;
}

.panel-group.accordion .panel:last-child {
  overflow: visible;
}

.modal .modal-content .modal-header .modal-info .panel-title > i {
  font-size: 18px;
  vertical-align: sub;
}

.text-mar-btm {
  margin-bottom: 25px;
}

.descr-video {
  color: #fff;
}

.video-holder {
  position: relative;
}

.descr-video .title-video {
  font-size: 22px;
  color: #fff;
}

.video-holder .hipervideo .hiperlink {
  display: inline-block;
  width: 60px;
  height: 45px;
  text-align: center;
  border-radius: 5px;
  background: transparent;
}

.video-holder .hipervideo {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 5px;
  cursor: pointer;
}

.position-panel.panel-chapter {
  display: block;
}

.panel-chapter .panel-body {
  padding: 0 20px 20px;
}

.panel-chapter .panel-body .panel-heading {
  margin: 0 -20px;
  margin-bottom: 25px;
  padding: 5px 20px;
  border-bottom: 1px solid #e7ecf3;
}

.caps-list .chapter-name {
  display: inline-block;
  overflow: hidden;
  width: 190px;
  vertical-align: sub;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ead-page .nano-content.no-scroll,
.ead-page .nano-menu-simple .nano-content {
  overflow: hidden;
}

.panel-accordion .position-panel .hipervideos-list .text-hipervideo {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.action-holder .panel-group {
  margin-bottom: 65px;
}

.ead-page .video-holder .modal {
  top: 35%;
}

.video-holder .modal .modal-dialog {
  width: 100vw;
  margin: 0 auto;
}

.video-holder .modal .modal-dialog .modal-header {
  display: flex;
  justify-content: space-between;
}

.video-holder .modal .modal-dialog .modal-title {
  overflow: hidden;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.video-holder .modal .modal-dialog .text-close-modal {
  margin: 2px 0 0 9%;
}

.video-holder .modal .modal-dialog .modal-body iframe {
  width: 100%;
  height: auto;
}

.panel-chapter .panel-body .panel-heading {
  margin-bottom: 15px;
}

.panel-chapter .panel-body .panel-heading .panel-title {
  line-height: 25px;
}

#slider-tips .item {
  display: block;
  width: 100%;
  height: auto;
  text-align: center;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.ead-page .slider-banner {
  overflow: hidden;
  background: #000;
}

.ead-page .slider-banner .content-area {
  padding: 0;
}

.slick-list {
  overflow: visible !important;
}

.ead-page {
  overflow-x: hidden;
  background-color: #F5F5F5;
}

.ead-page .panel-user .text {
  color: #fff;
}

.ead-page .avatar-image img {
  width: 100%;
}

.ead-page .logo-img {
  display: inline-block;
  width: 115px;
  height: 52px;
  text-align: center;
}

.ead-page .logo-img .logo {
  display: inline-block;
  width: 125px;
  height: 55px;
  text-indent: -9999px;
  background: url("/images/logo-normal.png") no-repeat;
  background-size: contain;
}

.ead-page .header-elements .logo-holder {
  position: absolute;
  left: 15px;
  margin-top: 5px;
}

.ead-page .nav li .icon-padding {
  padding: 0;
}

.ead-page body {
  background: #fff;
}

.ead-page #mainnav {
  height: 100%;
  background: transparent;
}

.ead-page .searchbox {
  margin-right: 0;
  position: relative;
  z-index: 1;
}

.ead-page #mainnav-container {
  position: absolute;
  z-index: 4;
  top: -59px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 420px;
  min-height: 0;
  padding-top: 40px;
}

.ead-page .nano-menu-simple {
  height: 260px !important;
}

.ead-page .nano-menu-simple .panel-user {
  height: auto;
}

.ead-page .nano-menu-simple .panel-user .pad-all {
  padding: 5px 15px;
}

.ead-page .nano-menu-simple .panel-user .panel-footer {
  display: none;
}

.ead-page .nano-menu-simple .panel-user .media {
  display: flex;
  height: 41px;
  margin-top: 0;
}

.ead-page .nano-menu-simple .panel-user .media .media-body {
  display: inline-block;
  width: auto;
}

.ead-page .nano-menu-simple .panel-user .media .media-body a {
  display: block;
  overflow: hidden;
  width: auto;
  margin-top: 2px;
  margin-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ead-page .notification-item {
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
}

.ead-page .img-radius {
  border: 1px solid #fff;
  border-radius: 50%;
}

.ead-page .panel-user {
  margin: 55px 0 0;
  text-align: center;
  color: #fff;
  border: 0;
  background: #e0221f;
  background: url("/images/texture-bg-dark.png") right top;
}

.ead-page .panel-user .panel-header {
  height: 50px;
  background: #e0221f;
  background: url("/images/texture-bg-darker.png") right top;
}

.ead-page .panel-user .panel-footer {
  text-align: left;
  background: #e0221f;
  background: url("/images/texture-bg-darker.png") right top;
}

.ead-page .panel-user .panel-footer a {
  color: rgba(255, 100, 100, 0.6);
}

.ead-page .padding-top {
  padding-top: 7px;
}

.ead-page .nav li a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.ead-page .mobile-menu {
  display: none;
  text-align: left;
}

.ead-page .mobile-menu .item-nav {
  padding: 10px 0;
}

.ead-page .mobile-menu .item-nav a {
  text-transform: uppercase;
  color: #fff;
}

.ead-page .menu-display {
  display: none;
}

.ead-page .footer-page img {
  width: 100%;
}

.ead-page .menu-display .item-nav a {
  text-transform: uppercase;
}

.ead-page .navbar-top-links .btn-mobile {
  top: 110%;
}

.ead-page .navbar-main li a {
  display: table-cell;
  height: 40px;
  padding: 0 12px;
  transition: all .4s;
  vertical-align: middle;
  color: #758697;
}

.ead-page .header-holder .nav-holder .nav-menu ul .menu-item {
  margin-bottom: 15px;
  display: block;
}

.ead-page .header-holder .nav-holder .nav-menu ul .menu-item .menu-link {
  color: #666;
  font-size: 14px;
}

.ead-page .header-holder .nav-holder .nav-menu ul .menu-item:last-child {
  margin-bottom: 0;
}

.ead-page .search-area .search-form {
  margin-right: 0;
}

.ead-page .search-area .search-icon {
  position: absolute;
  top: 8px;
  right: 10px;
  color: #7F7F7F;
}

.ead-page .search-area .searchbox > .input-group.custom-search-form input {
  color: rgba(91, 66, 46, 0.5);
  border-bottom-color: rgba(91, 66, 46, 0.5);
  font-size: 14px;
  line-height: 19px;
  padding: 6px 8px;
}

.top-bar .hamburger-button {
  position: absolute;
  top: 5px;
  left: 15px;
}

.ead-page .header-elements .nav-holder .menu-area {
  display: none;
}

.ead-page .header-elements .container-fluid .nav-holder .container-fluid {
  padding: 0;
}

.top-bar .hamburger-button .btn-mobile-ead {
  padding: 2px 7px;
  font-size: 16px;
  border: 1px solid #e0221f;
  border-radius: 5px;
  color: #e0221f;
  background-color: white;
}

.ead-page .search-area .form-control::placeholder {
  color: #999;
}

.ead-page .header-holder .name-holder {
  text-align: center;
  margin: 20px 0 10px;
}

.ead-page .header-holder .name-holder .name-user .user-avatar {
  display: inline-block;
}

.ead-page .notification-list {
  right: -59px;
}

.ead-page .searchbox .custom-search-form {
  width: 100%;
}

.ead-page ::-webkit-input-placeholder {
  color: #fff;
}

.ead-page .cards-area {
  margin-top: 0;
}

.ead-page :-moz-placeholder {
  color: #fff;
}

.ead-page .area-banner .banner {
  height: auto;
  margin-bottom: 30px;
  background-color: #d8d8d8;
}

.ead-page .information-area {
  margin-top: 0px;
}

.ead-page .content-holder {
  background-color: #F5F5F5;
}

.ead-page .information-area .title {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 15px;
  text-transform: uppercase;
  color: #000;
}

.ead-page .information-area .tag-item {
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 10px;
}

.ead-page .information-area .label-tag {
  font-size: 10px;
  display: inline-block;
  width: auto;
  padding: 2px 5px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  background: #666;
}

.ead-page .information-area .tags-area {
  padding-bottom: 10px;
  border-bottom: 2px dashed #ccc;
}

.ead-page .information-area .uc-course .uc-title {
  font-size: 12px;
  font-weight: 600;
  display: block;
  margin-bottom: 12px;
  padding: 6px 10px;
  color: #ff6b4d;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.ead-page .courses-done {
  margin-bottom: 0px;
}

.ead-page .courses-done .text {
  font-size: 10px;
  color: #fd9727;
}

.ead-page .courses-approved {
  margin-bottom: 0px;
}

.ead-page .courses-approved .text {
  font-size: 10px;
  color: #30ad63;
}

.ead-page .courses-disapproved .text {
  font-size: 10px;
  color: #e0221f;
}

.ead-page .information-area .title {
  color: #282828;
}

.ead-page .information-area .uc-course .uc-title {
  margin-bottom: 24px;
}

.ead-page .information-area .last-course .uc-course .btn {
  padding: 4px 25px;
  text-align: center;
  border-radius: 5px;
  background: #e0221f;
}

.ead-page .information-area .uc-course .btn i {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.ead-page .content-area {
  padding: 40px 0 0;
}

.ead-page .mainnav-area {
  display: block;
  height: 290px;
}

.ead-page .area-header {
  margin-bottom: 30px;
}

[class^="col-"]:not(.pad-no) {
  padding-left: 15px;
  padding-right: 15px;
}

.ead-page .area-header .title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  text-transform: uppercase;
  background: #e0221f;
  color: #fff;
  padding: 5px 10px;
}

.ead-page .card {
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #EAE2D8;
  border-radius: 2px;
  background-color: #fff;
}

.ead-page .card.card-preview {
  opacity: 0.6;
}

.ead-page .card.card-preview .card-title {
  position: relative;
}

.ead-page .card.card-preview .card-title .uc-title {
  color: #666;
}

.ead-page .card.card-preview .card-title .date-preview {
  color: #BBB;
  font-weight: 600;
  position: absolute;
  top: 36px;
  padding: 0 20px;
  font-size: 11px;
}

.ead-page .card.card-preview .card-info .footer-info .link-uc {
  font-weight: 600;
}

.ead-page .card img {
  width: 100%;
}

.ead-page .navbar-top-links.pull-right > li > a:hover {
  border-bottom: 1px solid #e6e6e6;
  background: #fff;
}

.ead-page .card .card-title .uc-title {
  font-size: 14px;
  font-weight: 600;
  height: 30px;
  margin-bottom: 35px;
  padding: 0 20px;
  color: #5B422E;
}

.ead-page .card .card-info .progress {
  height: 7px;
}

.ead-page .card .card-info .footer-info {
  padding: 15px 20px;
  border-top: 1px solid #EAE2D8;
}

.ead-page .card .link-uc {
  font-size: 11px;
  float: left;
  color: #fd9727;
}

.ead-page .card .link-uc.uc-done {
  color: #40800f;
}

.ead-page .card .link-uc.uc-avalaible {
  color: #0054f5;
}

.ead-page .card.uc-blocked {
  opacity: .6;
}

.ead-page .card .link-uc.uc-blocked {
  color: #8b8b8b;
}

.ead-page .card .link-uc.uc-unlocked {
  color: #076A42;
}

.ead-page .card .link-uc i {
  margin-right: 3px;
  vertical-align: baseline;
}

.ead-page .card .icon-right {
  float: right;
}

.ead-page .card .icon-right i {
  font-size: 12px;
  color: #076A42;
  position: relative;
  top: 2px;
}

.ead-page .banner img {
  width: 100%;
}

.ead-page .curse-page {
  clear: both;
}

.ead-page .mainnav-area > header {
  position: relative;
  z-index: 9;
  margin: 0 -15px;
}

.ead-page .navbar-main li .open-mobile-menu {
  color: #fff;
}

.ead-page .data-page .area-footer {
  text-align: right;
}

.ead-page .data-page .area-footer .button-edit {
  font-size: 12px;
  color: #fff;
  border-radius: 5px;
  background: #e0221f;
}

.ead-page .navbar-top-links:last-child > li {
  border-left: 1px solid #e6e6e6;
}

.ead-page .img-md {
  width: 50px;
  height: 50px;
}

.ead-page .nano-menu-simple .img-md {
  width: 34px;
  height: 34px;
}

.ead-page .text-forgot {
  display: inline-block;
  margin-bottom: 15px;
}

.ead-page .admin-actions {
  background: #291E15;
  text-align: right;
}

.ead-page .admin-actions .links-holder {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ead-page .admin-actions .links-holder > div > a {
  display: inline-flex;
  align-items: center;
  padding: 0px 15px;
  border: none;
}

.ead-page .admin-actions .links-holder > div > a:before {
  display: none;
}

.ead-page .admin-actions .links-holder > div > a i {
  font-size: 18px;
}

.ead-page .admin-actions .links-holder > div > a i.ti-lock, .ead-page .admin-actions .links-holder > div > a i.ti-power-off, .ead-page .admin-actions .links-holder > div > a i.ti-stats-up {
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  .ead-page .admin-actions .links-holder > div > a i {
    margin-right: 5px;
  }
}

.ead-page .select-holder .select-role {
  display: inline-block;
  width: 150px;
  margin: 6px 15px;
  padding: 3px 5px;
  color: #e0221f;
  border: 1px solid #bfbdbd;
  border-radius: 3px;
  background: #fff;
  outline: none;
  appearance: none;
  -moz-appearance: none;
}

.ead-page .select-holder .ti-angle-down {
  font-size: 11px;
  position: absolute;
  color: #e0221f;
  top: 15px;
  right: 22px;
  display: inline-block;
  pointer-events: none;
}

.ead-page .admin-actions .admin-btn {
  color: #f1f1f1;
  border-radius: 10px;
  padding: 5px 15px;
}

.ead-page .admin-actions a {
  position: relative;
  color: #f1f1f1;
  line-height: 30px;
  transition: all .1s;
}

.ead-page .admin-actions a:hover {
  color: #fff;
}

.ead-page .modal-exercise {
  bottom: 0;
  margin: 30px auto;
}

.ead-page .modal-exercise .modal-dialog {
  display: block;
  width: 100%;
  margin: 0 auto;
}

.ead-page .modal.modal-exercise:before {
  height: 0;
}

.text-practice {
  line-height: 21px;
  margin: 20px 0;
  text-align: justify;
}

.info-course .text-right .course-details {
  float: left;
  margin-top: 7px;
}

.info-course .text-right .course-details-required {
  display: flex;
  float: none;
  margin-top: 0;
  margin-bottom: 15px;
  justify-content: space-around;
}

.info-course .text-right .course-details .item-details {
  margin-right: 8px;
}

.info-course .text-right .course-details .item-details:last-child {
  margin-right: 0;
}

.answers-item.selected {
  background: #758697;
}

.answers-item.selected span {
  color: #fff;
}

@keyframes text-danger-alternate {
  to {
    color: #e0221f;
  }
}

.text-danger-alternate {
  animation: text-danger-alternate 1s infinite ease-in-out both;
  color: white !important;
}

/* COMPONENTE DE VÍDEOS */
.pointer {
  cursor: pointer;
}

.your-answer,
.correct-answer {
  display: inline-block;
}

.checked {
  color: #fff;
  border: 1px solid #2b425b;
  background: #758697;
}

.answers .correct {
  font-size: 12pt;
  color: #86c042;
}

.answers-multiple .answers-item {
  cursor: pointer;
}

.answers-multiple .checked {
  color: #fff;
}

/* UC */
.test-results .approved {
  color: green;
}

.test-results .no-padding {
  padding: 0 !important;
}

.test-results .txt-center {
  text-align: center !important;
}

.test-results .row {
  margin: 0;
  padding: 4px 0;
  border-top: 1px solid #ddd;
}

.test-results .row:last-child {
  padding: 0;
}

.test-results .modal-header {
  padding: 0 4px;
}

.test-results .modal-header > div {
  min-height: 42px;
  margin: 5px 0;
  padding: 10px 5px;
}

.test-results .modal-header > div:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.test-results .modal-header > div:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.test-results .modal-header .btn-close {
  float: right;
}

.test-results * {
  color: #4f5861;
}

.test-results .mobile {
  display: none;
}

.test-results .mobile .row:last-child {
  padding-bottom: 4px;
}

.test-results .desktop {
  display: block;
  padding: 10px;
}

.test-results .pd-10 {
  padding: 10px !important;
}

.test-results thead {
  font-weight: bold;
}

.test-results .bold {
  font-weight: bold;
}

.test-results .ti-thumb-up,
.test-results .ti-thumb-down {
  font-size: 15pt;
}

.test-results .approved {
  color: green;
}

.test-results .disapproved {
  color: #e0221f;
}

.scorm-course {
  border: none;
}

.nav-holder .action-nav-holder {
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 0;
}

/* Busca de UC */
.search-results {
  position: relative;
  left: 0px;
  display: none;
  width: 100%;
  padding-top: 10px;
  border: 1px #f1f1f1 solid;
  border-top: 0;
  background-color: white;
  z-index: 10;
}

.search-results .search-content {
  overflow-y: auto;
  height: 100%;
  max-height: 270px;
}

.search-results .search-alert {
  display: block;
  margin-bottom: 10px;
  padding: 5px 10px;
  transition: all .1s;
}

.search-results .search-item {
  display: block;
  margin-bottom: 5px;
  padding: 5px 10px;
  padding-bottom: 5px;
  transition: all .1s;
  border-bottom: 1px solid #f1f1f1;
}

.search-results .search-item:last-child {
  margin-bottom: 0;
}

.search-results .search-item img {
  float: left;
  width: 96px;
  height: 54px;
  margin-right: 10px;
}

.search-results .search-item .content span {
  display: block;
  padding: 0 0 10px 0;
}

.search-results .search-item:hover {
  color: #1c1c1c;
  background-color: #f1f1f1;
}

#polishop_comvc_banner {
  margin: 0 auto;
}

.banner-comvc .list-group {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.banner-comvc .list-group-item {
  width: 33.33%;
}

.banner-comvc .banner-image {
  margin-bottom: 10px;
}

.your-answer {
  padding: 20px;
  border-right: 1px solid #eee;
}

.y-answer-title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 20px;
}

.feedback {
  padding: 0 0 20px;
  padding-left: 20px !important;
}

.feedback-response {
  font-size: 28px;
  margin-bottom: 10px;
  text-align: center;
}

.feedback-question {
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.question-connect {
  padding: 10px 0px 10px 30px;
}

li {
  list-style: none;
}

.question-truefalse .option {
  float: left;
  display: inline-block;
  margin: 10px;
  width: calc(100% - 120px);
}

.question-truefalse .toggle-switch {
  float: left;
}

.toggle-switch {
  display: inline-block;
}

.checkbox.toggle.switch {
  width: 100px;
  cursor: pointer;
  height: 35px;
}

.switch .slide-button,
.toggle p span {
  display: none;
}

.toggle {
  position: relative;
  padding: 0;
}

.toggle label {
  position: relative;
  z-index: 3;
  display: block;
  width: 100%;
}

.toggle input {
  position: absolute;
  opacity: 0;
  z-index: 5;
}

.toggle p {
  position: absolute;
  left: -100px;
  width: 100%;
  margin: 0;
  padding-right: 100px;
  text-align: left;
  font-family: OpenSans-SemiBold, "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: .8em;
}

.toggle p span {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: block;
  width: 50%;
  margin-left: 100px;
  text-align: center;
}

.toggle p span:last-child {
  left: 50%;
}

.toggle .slide-button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
  display: block;
  width: 50%;
  height: 100%;
  padding: 0;
}

.toggle {
  display: block;
  height: 30px;
}

.toggle * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.toggle .slide-button {
  display: block;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.toggle label,
.toggle p {
  line-height: 30px;
  vertical-align: middle;
}

.toggle input:checked ~ .slide-button {
  right: 50%;
  background-color: #8bc34a;
}

/* Bugfix for older Webkit, including mobile Webkit.
 * http://css-tricks.com/webkit-sibling-bug/
 */
.switch, .toggle {
  -webkit-animation: bugfix infinite 1s;
}

@-webkit-keyframes bugfix {
  from {
    position: relative;
  }
  to {
    position: relative;
  }
}

.switch {
  background-color: #D6D6D6;
  color: #fff;
  font-weight: bold;
  text-align: center;
  text-shadow: 1px 1px 1px #191b1e;
  border-radius: 1px;
  padding: 2px 2px;
}

.switch input:checked + label {
  color: #333;
}

.switch .slide-button {
  border: 2px solid #D6D6D6;
  background-color: #e0221f;
  border-radius: 1px;
}

.switch p {
  color: #333;
  text-shadow: none;
}

.switch span {
  color: #fff;
}

.question-balloon .answers-balloons {
  display: block;
  width: 30%;
  float: left;
  min-height: 200px;
}

.question-balloon .answer-content {
  z-index: 99;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid;
  border-radius: 3px;
  cursor: pointer;
  background-color: white;
  transition: border-color .3s;
}

.question-balloon .character-balloon {
  width: 70%;
  float: left;
  padding-left: 30px;
  height: 100%;
}

.question-balloon .character-balloon .balloons {
  border: 1px solid #e0221f;
  background-color: rgba(239, 83, 80, 0.3);
  transition: background .3s;
  border-radius: 30px;
  width: 50%;
  height: 100%;
  min-height: 300px;
  padding: 20px;
  float: left;
}

.question-balloon .character-balloon .balloons .answer-content {
  border-color: #e0221f;
}

.question-balloon .character-balloon .character {
  position: relative;
  float: left;
  height: 300px;
  width: 200px;
  margin-left: 60px;
  background: url("../../images/character-female.png") 0/contain no-repeat;
}

.question-balloon .character-balloon .character-dialog {
  height: 40px;
  width: 60px;
  position: absolute;
  top: 0;
  left: -60px;
}

.question-balloon .character-balloon .character-dialog .dialog {
  background-color: rgba(239, 83, 80, 0.3);
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50px;
  border: 1px solid #e0221f;
  margin: 5px;
  position: absolute;
}

.question-balloon .character-balloon .character-dialog .d-1 {
  width: 20px;
  height: 18px;
}

.question-balloon .character-balloon .character-dialog .d-2 {
  left: 25px;
  top: 0px;
  width: 12px;
  height: 10px;
}

.question-balloon .character-balloon .character-dialog .d-3 {
  left: 40px;
  top: 10px;
}

.practice-script {
  overflow-y: auto;
  height: 293px;
}

.questions-items .active {
  transition: all .2s linear;
  border-color: #6f90ff !important;
  box-shadow: #6f90ff 0px 0px 10px;
}

.form-group-icon:after, .form-group-icon:before {
  display: table;
  clear: both;
  content: '';
}

.form-group-icon .input-icon {
  float: left;
  background-color: #e0221f;
  color: white;
  padding: 0 9px;
  height: 30px;
  border-radius: 0;
}

.form-group-icon .input-icon i {
  vertical-align: -webkit-baseline-middle;
}

.form-group-icon .form-control {
  float: left;
  width: auto;
}

/* #Events */
.filter-holder {
  text-align: left;
  margin-top: 30px;
  border-bottom: 1px solid #000;
}

.filter-holder .filter-events {
  width: 100%;
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .filter-holder {
    margin-top: 0px;
  }
  .filter-holder .filter-events {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .filter-holder .filter-events > .form-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 215px;
    max-width: 215px;
  }
  .filter-holder .filter-events > .form-group > label {
    flex: 0 0 auto;
    max-width: max-content;
    margin-right: 10px;
    margin-bottom: 0px;
  }
  .filter-holder .filter-events > .form-group > select {
    flex: 0 0 200px;
    max-width: 200px;
  }
}

@media screen and (min-width: 1024px) {
  .filter-holder {
    margin-top: 0px;
  }
  .filter-holder .filter-events {
    margin-bottom: 0px;
  }
  .filter-holder .filter-events .form-group {
    margin-bottom: 15px;
  }
}

.event-list {
  padding: 0;
}

.event-list .event-item {
  margin: 20px 0;
  color: #000;
  border-bottom: 1px solid #dedede;
  position: relative;
  padding: 0 15px;
}

.event-list .event-item:before, .event-list .event-item:after {
  clear: both;
  display: table;
  content: '';
}

.event-list .event-item .event-header {
  margin-bottom: 30px;
}

.event-list .event-item .event-header .event-title {
  line-height: 40px;
  margin: 0;
  color: #000;
}

.event-list .event-item .event-header .event-subtitle {
  font-size: 12px;
}

.event-list .event-item .event-header .event-subtitle span.date:after,
.event-list .event-item .event-header .event-subtitle span.organizer:after,
.event-list .event-item .event-header .event-subtitle span.hour:after {
  content: '-';
  padding: 5px;
}

.event-list .event-item .event-detail {
  margin: 30px 0 10px;
}

@media screen and (min-width: 1024px) {
  .event-list .event-item .event-header {
    float: left;
  }
  .event-list .event-item .event-detail {
    float: right;
    margin-top: 18px;
  }
}

.event-list > h2 {
  margin-bottom: 30px;
}

/* #Event Show */
.event-content {
  margin-bottom: 30px;
}

.event-content .event-image {
  width: 100%;
  max-width: 300px;
  float: left;
  margin-right: 30px;
}

.event-content .event-image img {
  width: 100%;
}

.event-content .event-description {
  float: left;
}

.event-content .event-description a {
  margin-top: 10px;
}

.event-gallery .event-image-list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.event-gallery .event-image-list .event-image {
  position: relative;
  height: 250px;
  padding-bottom: 10px;
  flex-basis: 23%;
}

.event-gallery .event-image-list .event-image:nth-child(4n) {
  margin-right: 0;
}

.event-gallery .event-image-list .event-image .image {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 1px solid #e9e9e9;
}

.event-gallery .event-image-list .event-image .image:hover .open-image {
  opacity: 1;
}

.event-gallery .event-image-list .event-image .image .open-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all .2s;
}

.event-gallery .event-image-list .event-image .image .open-image i {
  color: rgba(255, 255, 255, 0.7);
  font-size: 50px;
  height: 50px;
  width: 50px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.content-area .area-banner {
  margin-bottom: 30px;
}

.last-access {
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 12px;
}

.last-access span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #ccc;
  border: 1px solid #999;
}

/* #COMVC */
.form-ead .btn {
  border: none;
}

.comvc .menu {
  display: flex;
  justify-content: space-between;
}

.comvc .menu .menu-item {
  position: relative;
  width: 25%;
  text-align: center;
  cursor: pointer;
}

.comvc .menu .menu-item.active .menu-item-icon, .comvc .menu .menu-item:hover .menu-item-icon {
  background-color: #ed1c24;
  color: white;
}

.comvc .menu .menu-item.active .menu-item-icon i, .comvc .menu .menu-item:hover .menu-item-icon i {
  transform: scale(1.4);
}

.comvc .menu .menu-item:hover .sub-menu {
  display: block;
}

.comvc .menu .menu-item .menu-item-icon {
  width: 70px;
  height: 70px;
  font-size: 24px;
  border: 2px solid #ed1c24;
  color: #ed1c24;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 7px;
  transition: all .1s linear;
}

.comvc .menu .menu-item .menu-item-icon i {
  transition: all .2s linear;
}

.comvc .menu .menu-item .menu-item-title {
  text-align: center;
  color: #ed1c24;
}

.comvc .menu .menu-item .sub-menu-cover {
  position: absolute;
  top: 0;
  width: 100%;
  height: 120%;
}

.comvc .menu .menu-item .sub-menu-cover:hover .sub-menu {
  display: block;
}

.comvc .menu .menu-item .sub-menu {
  display: none;
  position: absolute;
  left: -40px;
  right: -40px;
  margin: auto;
  top: 110px;
  padding: 10px;
  border-radius: 10px;
  background-color: #e0221f;
  z-index: 10;
  width: 150px;
}

.comvc .menu .menu-item .sub-menu li {
  z-index: 10;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.comvc .menu .menu-item .sub-menu li:last-child {
  border: none;
}

.comvc .menu .menu-item .sub-menu:before {
  content: '';
  display: block;
  background-color: #e0221f;
  position: absolute;
  top: -10px;
  left: 0;
  border-style: solid;
  right: 0;
  width: 10px;
  margin: auto;
  border-width: 0 10px 10px 10px;
  border-color: #fafafa #fafafa #e0221f #fafafa;
}

.comvc .menu .menu-item .sub-menu .sub-menu-item {
  font-size: 12px;
  display: block;
  text-align: left;
  color: white;
  padding: 5px 0;
}

.comvc .menu .menu-item .sub-menu .sub-menu-item:hover {
  opacity: .8;
}

.comvc .share .share-item {
  position: relative;
  text-align: left;
  font-size: 14px;
  padding-left: 5%;
  margin-bottom: 5px;
}

.comvc .share .share-item .share-title {
  color: #ed1c24;
  padding: 7px 15px;
  background-color: white;
  border-radius: 10px;
  border: 2px solid #ed1c24;
  display: inline-block;
  width: 150px;
  cursor: default;
}

.comvc .share .share-item .share-title i {
  font-size: 20px;
}

.comvc .share .share-item .link {
  display: block;
}

.comvc .share .share-item .copy {
  padding: 3px 10px;
  border-radius: 5px;
  border: 1px solid #999;
  background-color: #999;
  color: white;
  position: relative;
  top: -5px;
  transition: all .2s;
  cursor: pointer;
  margin-left: 10px;
}

.comvc .share .share-item .copy:before {
  content: '';
  display: block;
  background-color: #999;
  position: absolute;
  top: 7px;
  left: -9px;
  border-style: solid;
  width: 0;
  height: 0;
  margin: auto;
  border-width: 5px 8.7px 5px 0;
  border-color: #fafafa #999 #fafafa #fafafa;
}

.comvc .share .share-item .copy:hover {
  background-color: white;
  color: #999;
}

.comvc .share .share-item .share-link {
  display: inline-block;
  color: #444;
  padding-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* FOOTER */
.footer {
  padding: 26px 0;
  background-color: #ecf0f5;
  color: white;
  font-family: 'Exo';
}

.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer a {
  font-family: 'Exo';
}

.footer .left-content {
  text-align: left;
}

.footer .left-content div {
  font-family: 'Exo';
}

.footer .left-content > div {
  margin: 20px 0;
  font-size: 19px;
}

.footer .left-content .logo {
  margin: 30px 0;
}

.footer .comvc .left-content .logo {
  margin: 40px 0;
}

.footer .comvc .right-content > div {
  font-size: 26px;
  margin: 0;
}

.footer .right-content {
  text-align: right;
}

.footer .right-content div {
  font-family: 'Exo';
}

.footer .right-content > div {
  font-size: 14px;
  margin: 10px 0;
}

.footer .right-content .contact a {
  font-size: 19px;
}

.footer .right-content .address {
  margin-bottom: 30px;
}

.footer .right-content .text-red {
  color: #e0221f;
}

.footer .right-content div.text-big {
  font-size: 89px;
}

.footer .right-content div.text-normal {
  font-size: 37px;
}

.footer .footer-content {
  width: 50%;
}

.footer .contact a {
  display: block;
  font-size: 26px;
  line-height: 24px;
  color: #e0221f;
}

/* END FOOTER */
/* Media Queries */
@media screen and (max-width: 767px) {
  .question .item-connect.answer-connect {
    margin-left: 40px;
  }
  .question .question-simple .answer {
    left: 0px;
  }
  .question-balloon div.character-balloon {
    width: 100%;
    float: none;
    padding-left: 0px;
  }
  .question-balloon ul.answers-balloons {
    width: 100%;
    float: none;
    margin-bottom: 20px;
    min-height: 10px;
  }
  .question-balloon .character-balloon ul.balloons {
    width: 100%;
    height: auto;
  }
  .question-balloon .character-balloon div.character {
    width: 130px;
    float: right;
  }
  .question-balloon .character-balloon .character-dialog .d-1 {
    top: -12px;
  }
  .event-gallery .event-image-list li.event-image {
    flex-basis: 49%;
    padding: 0;
    padding-bottom: 10px;
  }
  .event-content {
    text-align: center;
  }
  .event-content div.event-image {
    float: none;
    margin: 0 auto 10px;
  }
  .event-content div.event-description {
    margin-left: 0;
  }
}

/* FOOTER MOBILE */
@media screen and (max-width: 768px) {
  .footer .social {
    justify-content: center;
    flex-wrap: wrap;
  }
  .footer .social .social-item {
    margin: 10px;
  }
  .footer .container, .footer .container.comvc {
    justify-content: center;
    flex-wrap: wrap;
  }
  .footer .footer-content {
    width: 100% !important;
    text-align: center !important;
  }
}

@media screen and (max-width: 425px) {
  .footer .left-content > div {
    margin: 10px 0;
    font-size: 14px;
  }
  .footer .left-content a {
    font-size: 16px;
  }
  .footer .right-content div.text-big {
    font-size: 60px;
  }
  .footer .right-content div.text-normal {
    font-size: 24px;
  }
}

/* END FOOTER MOBILE */
@media screen and (max-width: 480px) {
  .event-gallery .event-image-list li.event-image {
    flex: 1 100%;
    padding: 0 0 15px 0;
  }
  .expiry-content {
    margin-bottom: 0;
  }
  .comvc ul.menu {
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .comvc ul.menu li.menu-item {
    width: 33%;
    margin-bottom: 30px;
  }
  .comvc .share .share-item {
    margin-bottom: 30px;
    padding: 0;
  }
  .comvc .share .share-link {
    display: inline-block;
    direction: rtl;
    padding: 0;
    width: 100%;
  }
  .row {
    margin: 0;
  }
  .nav-holder {
    margin-top: 30px;
  }
  .ead-page .header-holder .name-holder {
    position: absolute;
    top: 50px;
    right: 15px;
    width: 50%;
    margin: 0;
  }
  .ead-page .header-holder .name-holder .name-user {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ead-page .header-holder .name-holder .user-avatar img {
    width: 25px;
    height: 25px;
  }
  .ead-page .header-holder .name-holder .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
  }
  .accordion-item {
    display: flex;
    align-items: center;
    height: 30px;
  }
  .accordion-item .accordion-image {
    width: 30px;
  }
  .accordion-item .accordion-image img {
    width: 100%;
  }
  .accordion-item .accordion-image.normal {
    display: none;
  }
  .banners-slider .item {
    margin: 0 !important;
  }
  .modal-dialog {
    margin: 0;
    width: 100%;
  }
}

.ead-page .banners-holder {
  margin: 12px 0 30px;
}

.ead-page .banners-holder .area-banner {
  margin-bottom: 0px;
}

@media screen and (min-width: 768px) {
  .banners-holder .content-area .area-banner .banners-slider img {
    width: 100%;
  }
  .comvc .menu {
    margin-bottom: 30px;
  }
  .ead-page .content-holder {
    padding-top: 20px;
  }
  .header-holder .header-elements .top-bar {
    margin: 0;
    margin-right: 0px;
    height: 88px;
    border-bottom: 0;
  }
  .ead-page .header-holder .header-elements .container {
    position: relative;
  }
  .ead-page .header-elements .logo-holder {
    position: relative;
    left: 0;
    top: 11px;
    z-index: 10;
  }
  .nav-holder .action-nav-holder {
    right: 25px;
  }
  .header-holder .menu-area {
    margin-left: 0;
    width: 100%;
    margin-bottom: -10px;
    margin-top: 10px;
    padding: 10px 0;
  }
  .ead-page .header-holder .nav-holder .nav-menu ul {
    margin-bottom: 0;
  }
  .ead-page .header-holder .nav-holder .nav-menu ul .menu-item {
    margin: 30px 20px;
    display: inline-block;
  }
  .ead-page .header-holder .nav-holder .nav-menu ul .menu-item .menu-link {
    display: inline-block;
    background: #FFF;
    padding: 8px 11px;
    border-radius: 10px;
  }
  .ead-page .header-holder .nav-holder .nav-menu ul .menu-item:first-child {
    margin-left: 0;
  }
  .ead-page .header-holder .name-holder {
    position: absolute;
    top: 0px;
    bottom: 0;
    right: 15px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .ead-page .top-bar .hamburger-button {
    margin-right: 0;
    margin-top: 14px;
    left: 185px;
  }
  .info-course .text-right .course-details .item-details {
    margin-right: 20px;
  }
  .info-course .text-right .course-details-required {
    float: left;
    margin-top: 7px;
    margin-bottom: 0;
  }
  .panel-tips .panel-body .holder-control {
    position: absolute;
    bottom: 15px;
    width: 90%;
  }
  .modal-practice .modal-body {
    width: 50%;
    margin: 0 auto;
  }
  .modal-practice .modal-body > p {
    text-align: center;
  }
  .item-reaction-holder .panel .panel-body {
    width: 90%;
    padding: 0 20px;
  }
  .item-reaction-holder .panel .panel-body .pre-test {
    float: left;
    margin-top: 18px;
  }
  .item-reaction-holder .panel .panel-body .text-right {
    float: right;
    margin-top: 9px;
  }
  .ead-page .modal-exercise {
    margin: auto;
  }
  .ead-page .modal-exercise .modal-dialog {
    width: 90%;
  }
  .ead-page .modal-dialog .modal-content .modal-body .owl-wrapper .owl-item {
    width: 538px;
  }
  .ead-page .single-page {
    margin-top: 30px;
  }
  .ead-page .logo-xs {
    display: none;
  }
  .ead-page .logo-img .logo {
    width: 100%;
    height: 91px;
  }
  .ead-page .logo-sm {
    display: inline-block;
    width: 168px;
    height: 91px;
  }
  .ead-page .border-item-right {
    border-right: 1px solid #e6e6e6;
  }
  .ead-page .data-page.cards-area {
    margin-top: 170px;
  }
  .ead-page .notification-list {
    right: 0;
  }
  .ead-page .cards-area {
    margin-top: 0;
  }
  .ead-page .curse-page {
    margin-top: 170px;
  }
  .ead-page .mainnav-area > header {
    position: relative;
    z-index: 9;
    margin: 0;
  }
  .ead-page #mainnav-container {
    z-index: 11;
    top: -55px;
    left: auto;
    max-width: 170px;
    padding-top: 0;
  }
  .ead-page .panel-user .panel-header {
    height: 40px;
  }
  .ead-page .menu-display {
    display: -ms-flexbox;
    display: flex;
    margin-left: 0;
  }
  .ead-page .mobile-menu {
    display: none !important;
  }
  .ead-page .nav > .btn-mobile {
    display: none;
  }
  .ead-page .name-holder .name-user .user-avatar .img-md {
    height: 30px;
    width: 30px;
  }
  .ead-page .search-area {
    position: absolute;
    top: 28px;
    left: 210px;
    width: 300px;
  }
  .ead-page .searchbox {
    width: 100%;
    display: block;
  }
  .ead-page .item-nav {
    position: relative;
  }
  .ead-page .search-area .search-icon {
    top: 8px;
    color: #808080;
  }
  .ead-page .search-area .form-control {
    color: #b3b3b3;
  }
  .ead-page ::-webkit-input-placeholder {
    color: #b3b3b3;
  }
  .ead-page :-moz-placeholder {
    color: #b3b3b3;
  }
  .ead-page .mainnav-area {
    height: 40px;
  }
  .ead-page .content-area {
    clear: both;
    width: 100%;
    margin-bottom: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .ead-page .slider-banner .content-area {
    margin-bottom: 0;
  }
  .ead-page .cards-area {
    padding: 0;
  }
  .ead-page .navbar-content {
    margin-left: 173px;
  }
  .ead-page .footer-page img {
    display: block;
    width: 500px;
    margin: 0 auto;
  }
  .ead-page #navbar-container {
    box-shadow: none;
  }
  .ead-page #mainnav-container {
    height: 253px;
  }
  .ead-page .information-area {
    overflow: hidden;
    text-align: right;
    position: relative;
    top: -3px;
  }
  .ead-page.comvc-page .information-area {
    overflow: hidden;
    text-align: left;
    position: relative;
    top: 10px;
  }
  .header-holder.comvc .information-holder {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .ead-page .panel .pad-all {
    padding: 0 15px;
  }
  .ead-page .panel .pad-all .media {
    margin-top: 10px;
  }
  .ead-page .information-area .tags-area {
    width: 100%;
    margin-bottom: 25px;
    padding-right: 0;
    padding-bottom: 6px;
    border-bottom: 0;
  }
  .ead-page .information-area .last-course {
    float: right;
    width: 48%;
  }
  .ead-page .information-area .title {
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 900;
    color: #000;
  }
  .ead-page .courses-done {
    margin-bottom: 4px;
  }
  .ead-page .courses-approved {
    margin-bottom: 4px;
  }
  .ead-page .comvc .header-page .top-header {
    height: 200px;
  }
  .ead-page .information-area .label-tag {
    height: 18px;
  }
  .ead-page .information-area .label-tag span {
    line-height: 15px;
    display: block;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ead-page .panel-user .panel-footer {
    height: 30px;
    padding: 5px 15px;
  }
  .ead-page .notification-list .notification-item {
    border-right: 0;
  }
  .ead-page .notification-list .bord-top .box-block {
    padding: 0;
  }
  .ead-page .header-elements {
    position: relative;
    padding-bottom: 0;
    margin: 0px;
  }
  .video-holder .modal .modal-dialog .modal-body iframe {
    height: 371px;
  }
  .modal-exercise .flexbox-area {
    display: block;
    width: 100%;
  }
  .modal-exercise .flexbox-area .btn {
    float: left;
  }
  .modal-exercise .flexbox-area .progress-exercise {
    position: absolute;
    right: 0;
    left: 0;
    width: 100px;
    margin: 0 auto;
  }
  .modal-exercise .flexbox-area .test-next-end #pretest-next,
  .modal-exercise .flexbox-area .test-next-end #end-pre-test {
    float: right;
  }
  .position-panel .holder-control {
    margin-top: 20px;
  }
  .panel-accordion .position-panel .tips-list.owl-carousel {
    margin: auto;
  }
  .ead-page .video-holder .modal {
    top: 15%;
    width: 600px;
    margin: 30px auto;
  }
  .video-holder .modal .modal-dialog {
    width: 100%;
  }
  .action-holder .panel-group .position-panel:first-child {
    right: 0;
  }
  .action-holder .panel-group .position-panel:nth-child(4) {
    right: 0;
  }
  .video-holder .hipervideo .hiperlink {
    width: 140px;
    height: 90px;
  }
  .video-holder .hipervideo {
    top: 30px;
    right: 10px;
  }
  div[data-uc-id="227"] .video-holder .hipervideo {
    right: auto;
    left: 10px;
  }
  .accordion > .panel {
    background-color: transparent;
  }
  .accordion > .panel:first-child {
    margin-top: 5px;
  }
  .icon-accordion > i {
    line-height: 63px;
  }
  .icon-accordion {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background: #000;
  }
  .accordion-active .icon-accordion {
    background: #fff;
  }
  .panel-accordion .panel-heading .text-center a {
    width: 100%;
    height: auto;
    padding-top: 20px;
    border-radius: 0 0 5px 5px;
    background: transparent;
  }
  .panel-relative {
    position: relative;
    width: 150px;
  }
  .gradient-circle {
    display: block;
    float: none;
    width: 72px;
    height: 72px;
    margin: 0 auto;
    padding: 3px;
    border-radius: 50%;
  }
  .panel-accordion .panel-heading .text-center .accordion-active {
    width: 100%;
  }
  .panel-accordion > .panel {
    border-radius: 5px 5px 0 0 !important;
  }
  .accordion-item > span {
    font-size: 16px;
    font-weight: 600;
    float: none;
    margin: 0 auto;
  }
  .panel-accordion .panel-heading .panel-title {
    border-radius: 0 0 5px 5px;
  }
  .circle-warning {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff9902+0,f2d000+100 */
    background: #ff9902;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ff9902 0%, #f2d000 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #ff9902 0%, #f2d000 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ff9902 0%, #f2d000 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9902', endColorstr='#f2d000',GradientType=0 );
    /* IE6-9 */
  }
  .circle-success {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8bc34a+0,d5ff00+100 */
    background: #8bc34a;
    /* Old browsers */
    background: -moz-linear-gradient(top, #8bc34a 0%, #d5ff00 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #8bc34a 0%, #d5ff00 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #8bc34a 0%, #d5ff00 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bc34a', endColorstr='#d5ff00',GradientType=0 );
    /* IE6-9 */
  }
  .circle-purple {
    background: #ef5350;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ef5350 0%, #ff0077 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #ef5350 0%, #ff0077 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ef5350 0%, #ff0077 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef5350', endColorstr='#ff0077',GradientType=0 );
    /* IE6-9 */
  }
  .circle-primary {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#30c3fb+0,8400ff+100 */
    background: #30c3fb;
    /* Old browsers */
    background: -moz-linear-gradient(top, #30c3fb 0%, #8400ff 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #30c3fb 0%, #8400ff 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #30c3fb 0%, #8400ff 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30c3fb', endColorstr='#8400ff',GradientType=0 );
    /* IE6-9 */
  }
  .circle-caps {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ab46bc+0,ff008d+100 */
    background: #ab46bc;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ab46bc 0%, #ff008d 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #ab46bc 0%, #ff008d 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ab46bc 0%, #ff008d 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ab46bc', endColorstr='#ff008d',GradientType=0 );
    /* IE6-9 */
  }
  .panel-accordion .panel-heading .text-center .collapsed {
    background: transparent;
  }
  .panel-accordion .panel-heading .text-center .collapsed .icon-accordion {
    background: #000;
  }
  .panel-info-course > .panel-body {
    position: relative;
    padding-top: 15px;
  }
  .panel-info-course > .panel-body .descr-course {
    float: left;
    width: 60%;
    padding-top: 0;
    border-bottom: 0;
  }
  .panel-info-course > .panel-body .info-course {
    float: left;
    width: 40%;
    margin-top: -12px;
    padding-top: 15px;
    border-left: 1px solid #e7ecf3;
  }
  .try-evaluate {
    line-height: 30px;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 0;
    color: #758697;
  }
  .final-evaluation-holder .info-area > .text-right {
    display: flex;
    justify-content: space-between;
  }
  .panel-evaluate-success {
    display: flex;
    justify-content: space-between;
  }
  .panel-evaluate-success .panel-body {
    padding: 10px 20px;
  }
  .panel-title {
    line-height: 52px;
  }
  .panel-evaluate-certificate .title-area {
    float: left;
    width: 25%;
  }
  .panel-evaluate-certificate .info-area {
    float: right;
    padding: 0;
    width: 75%;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 52px;
  }
  .panel-evaluate-certificate .info-area p.pre-test {
    margin-bottom: 0;
    flex-basis: 56%;
  }
  .panel-item-course .img-course {
    display: inline-block;
    float: left;
    width: 43%;
    height: 135px;
    margin-right: 30px;
    margin-bottom: 0;
  }
  .panel-item-course .info-course > p {
    margin-bottom: 35px;
  }
  .panel-item-course .info-course {
    float: left;
    width: 52.5%;
  }
  .modal .modal-header > .modal-info .panel-title {
    line-height: 20px;
    padding: 0 0 0 20px;
  }
  .modal .modal-header > .modal-info {
    text-align: right;
  }
  .modal-info > .button-area-info {
    display: flex;
    justify-content: space-between;
  }
  .button-area-info .text-close-modal {
    margin-top: 6px;
    margin-bottom: 0;
  }
  .time-count {
    display: block;
    margin-top: 10px;
  }
  .drag-holder {
    display: flex;
    margin-bottom: 120px;
    justify-content: space-around;
  }
  .drag-holder > .drag-item {
    margin-bottom: 0;
  }
  .video-holder {
    margin-top: 20px;
  }
  .video-holder > iframe {
    height: 315px;
  }
  .video-holder > iframe.scorm-course {
    height: 550px;
  }
  .action-holder > .panel-group {
    position: relative;
    display: flex;
    justify-content: flex-start;
  }
  .accordion > .position-panel.panel-chapter {
    top: -1px;
    right: 0;
    overflow: hidden;
    width: 260px;
    height: auto;
    max-height: 150px;
    background: #fff;
  }
  .position-panel {
    position: absolute;
    top: -230px;
    width: 338px;
    height: 230px;
  }
  .panel-accordion .position-panel {
    overflow: hidden;
  }
  .panel-accordion .position-panel .archive-list,
  .panel-accordion .position-panel .hipervideos-list {
    overflow-x: hidden;
    overflow-y: auto;
    height: auto;
    max-height: 120px;
    margin: 0 -20px;
    padding: 0 20px;
  }
  .panel-accordion .position-panel .tips-list {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100px;
  }
  .holder-chapters .caps-list {
    margin-bottom: 10px;
  }
  .holder-chapters .caps-list:last-child {
    margin-bottom: 0;
  }
  .position-panel-right {
    right: 0;
  }
  .attempts-area {
    display: block;
    margin-bottom: 15px;
  }
  .video-holder {
    position: relative;
  }
  .descr-video .title-video {
    font-size: 34px;
  }
  .descr-video .text-video {
    font-size: 16px;
  }
  .text-close-modal {
    margin-right: 20px;
  }
  .panel-sm-flex {
    display: flex;
    justify-content: space-between;
  }
  .item-practice-holder .panel .info-area .pre-test {
    float: left;
    margin-top: 17px;
    margin-bottom: 0;
  }
  .item-practice-holder .panel .info-area {
    width: 68%;
    padding: 0 20px;
  }
  .item-practice-holder .panel .info-area .text-right {
    float: right;
    margin-top: 10px;
  }
  .evaluate-unterminated {
    display: flex;
    justify-content: space-between;
  }
  .evaluate-unterminated .info-area {
    width: 70%;
    padding: 0 20px;
  }
  .evaluate-unterminated .info-area .pre-test {
    float: left;
    margin-top: 15px;
    margin-bottom: 0;
  }
  .evaluate-unterminated .info-area .text-right {
    float: right;
    margin-top: 9px;
  }
  .info-course-holder .img-course {
    height: 330px;
  }
  .holder-chapters {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 110px;
    margin: 0 -20px;
    padding: 0 20px;
  }
  .caps-list .chapter-name {
    width: 160px;
  }
  .modal-exercise .modal-info.modal-info-time.text-center {
    display: flex;
    justify-content: space-between;
  }
  .modal.modal-exercise .modal-content .modal-header .modal-info .panel-title {
    margin-top: 6px;
  }
  .ead-page .navbar-content {
    border-bottom: 1px solid #e6e6e6;
  }
  .event-list .event-gallery .event-image-list {
    justify-content: flex-start;
  }
  .event-gallery .event-image-list li.event-image {
    margin-right: 19px;
  }
  .nav-holder .name-holder .name-user > a {
    max-width: 210px;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
  }
  .accordion-item .text-hiper {
    color: white;
  }
  .accordion-image {
    width: 80px;
    height: 80px;
    padding: 0;
    margin: -4px auto;
  }
  .accordion-image.hover {
    display: none;
  }
  .accordion-image img {
    width: 100%;
  }
  .accordion-active .accordion-image.normal {
    display: none;
  }
  .accordion-active .accordion-image.hover {
    display: block;
  }
  .accordion-active .text-hiper {
    color: #e0221f;
  }
  .search-area-desktop .custom-search-form .search-results {
    width: 541px;
    position: absolute;
    left: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .header-holder .menu-area .menu-item {
    border-left: 1px solid #B9B9B9;
  }
  .header-holder .menu-area .menu-item:last-child,
  .header-holder .menu-area .menu-item:first-child {
    border: none;
  }
  .ead-page .single-page {
    margin-top: 10px;
  }
  .container-md-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
  }
  .header-holder .menu-area {
    margin-top: 0;
    padding: 21px 0;
    position: absolute;
    top: -1px;
    background: transparent;
    right: 7px;
    width: calc(100% - 280px);
    margin-right: 0;
    display: inline-block !important;
  }
  .ead-page .nav-holder .name-holder .name-user > a {
    max-width: 250px;
  }
  .ead-page .logo-img {
    width: 170px;
    height: 70px;
  }
  .ead-page .header-elements .logo-holder {
    z-index: 10;
  }
  .ead-page .logo-img .logo {
    height: 90px;
  }
  .top-bar .hamburger-button .btn-mobile-ead {
    display: none;
  }
  .ead-page .header-holder .nav-holder .nav-menu ul .menu-item {
    margin: 0;
  }
  .ead-page .header-holder .nav-holder .nav-menu ul .menu-item:first-child {
    margin-left: 0;
  }
  .ead-page .header-holder .nav-holder .nav-menu ul .menu-item:last-child {
    margin-right: 0px;
    position: absolute;
    right: 0;
  }
  .panel-md-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .panel-md-flex .panel-body {
    width: 79%;
    padding: 0 20px;
  }
  .panel-md-flex .panel-body .pre-test {
    float: left;
    margin-top: 21px;
  }
  .panel-md-flex .panel-body .text-right {
    float: right;
    margin-top: 15px;
  }
  .video-holder .hipervideo {
    top: 127px;
    right: 26px;
  }
  .video-holder .hipervideo .hiperlink {
    width: 157px;
    height: 118px;
  }
  #modal-pre-test .modal-content {
    width: 900px;
  }
  .info-course-holder .img-course {
    height: 281px;
    background-position: center center;
  }
  .panel-info-course > .panel-body .descr-course {
    width: 54%;
  }
  .panel-info-course > .panel-body .info-course {
    width: 46%;
    padding-bottom: 5px;
  }
  .panel-item-course .info-course {
    width: 60%;
  }
  .panel-flex {
    display: flex;
  }
  .panel-flex .panel-body {
    padding: 0 20px;
  }
  .panel-flex .panel-body .pre-test {
    float: left;
    margin: 16px 0 0 30px;
  }
  .final-evaluation-holder .panel-evaluate-certificate .info-area p.pre-test {
    flex-basis: 70%;
    position: relative;
    left: -20px;
    float: left;
  }
  .final-evaluation-holder .info-area > .text-right {
    display: inline-block;
  }
  .panel-flex .info-area {
    margin-left: 10px;
  }
  .panel-flex .info-area .text-right {
    margin-top: 0px;
    margin-left: 0px;
  }
  .certificate-holder {
    margin-bottom: 40px;
  }
  .certificate-holder .panel-flex {
    justify-content: space-between;
  }
  .certificate-holder .panel-flex .info-area .text-right .btn {
    margin-top: 10px;
    margin-bottom: 9px;
  }
  .certificate-holder .panel-flex .info-area {
    width: 80%;
    margin-left: 0;
  }
  .panel-flex .panel-body .pre-test {
    margin-left: 10px;
  }
  .modal-dialog {
    width: 900px;
  }
  .modal .modal-header > .modal-info {
    float: right;
    margin-top: -20px;
  }
  .modal-info-time {
    display: flex;
  }
  .button-area-info .text-close-modal {
    margin-top: 6px;
    margin-right: 20px;
  }
  .modal .modal-content .modal-header .modal-info .panel-title {
    margin-top: 6px;
  }
  .flexbox-area {
    display: inline-block;
    width: 100%;
  }
  .flexbox-area .btn {
    float: left;
  }
  .flexbox-area .progress-exercise {
    float: left;
    width: 63%;
    margin-left: 120px;
    text-align: center;
  }
  .flexbox-area .test-next-end {
    float: right;
    text-align: right;
  }
  .drag-holder {
    display: inline-block;
    width: 100%;
  }
  .drag-holder > .drag-item {
    display: inline-block;
    margin: 0 10px;
  }
  .title-area-exercise {
    display: none;
  }
  .questions-holder {
    float: left;
    width: 47%;
    margin-right: 50px;
  }
  .answers-holder {
    float: left;
    width: 47%;
  }
  .connect-aux {
    top: 0;
    right: -1px;
    bottom: 0;
    left: auto;
    width: 10px;
    height: 20px;
    margin: auto 0;
    border-right: 0;
    border-bottom: 1px solid;
    border-radius: 25px 0 0 25px;
  }
  .white-space {
    right: -3px;
    bottom: 0;
    width: 4px;
    height: 18px;
  }
  .question-connect {
    padding-bottom: 10px;
  }
  .connect-top {
    left: -10px;
  }
  .question-connect-active {
    border-right: 0;
    border-radius: 3px 0 0 3px;
  }
  .answer-connect-inactive {
    position: relative;
    right: 52px;
    display: inline-block;
    border-left: 0;
    border-radius: 0 3px 3px 0;
  }
  .answer-connect-inactive .connect-top {
    display: none;
  }
  .panel-flex .panel-body .evaluate-margin-left {
    margin-left: 0;
  }
  .accordion > .position-panel.panel-chapter {
    top: -63px;
    left: auto;
    width: 400px;
    max-height: 230px;
  }
  .position-panel {
    left: -90px;
  }
  .action-holder .panel-group .panel:nth-child(2) .position-panel {
    left: 0;
  }
  .panel-accordion > .panel {
    border-radius: 5px !important;
  }
  .action-holder .panel-accordion .panel {
    border-radius: 5px 5px 0 0 !important;
  }
  .modal .modal-header > .modal-info-time {
    margin-top: -28px;
  }
  .modal .evaluate-header > .modal-info {
    margin-top: -21px;
  }
  .video-holder > iframe {
    height: 585px;
  }
  .video-holder > iframe.scorm-course {
    height: 710px;
  }
  .action-holder {
    clear: both;
    min-height: 147px;
  }
  .action-holder .panel-group {
    min-height: 147px;
  }
  .action-holder .panel-group .position-panel:first-child {
    top: -110px;
  }
  .video-holder .descr-video {
    float: left;
    min-height: 121px;
    width: 55%;
  }
  .item-practice-holder .panel .info-area {
    width: 79%;
  }
  .evaluate-unterminated .info-area {
    width: 79%;
  }
  .panel-item-course .img-course {
    width: 350px;
    height: 197px;
    background-position: center center;
  }
  .info-course .text-right {
    position: absolute;
    bottom: 20px;
    padding-right: 22px;
    width: 60%;
  }
  .panel-info-course > .panel-body .info-course > p:nth-child(2) {
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .final-evaluation-holder .panel-flex.panel-bordered-danger .info-area {
    margin-left: 46px;
  }
  .panel-evaluate-certificate .info-area {
    margin-left: 0px;
  }
  .pre-test-holder .panel-bordered-success.panel .info-area,
  .pre-test-holder .panel-bordered-danger.panel .info-area {
    height: 60px;
    margin-left: 87px;
  }
  .pre-test-holder .panel-bordered-danger .info-area {
    margin-left: 87px;
  }
  #modal-evaluate .modal-content {
    width: 900px;
  }
  .event-gallery .event-image-list li.event-image {
    flex-basis: 23.5%;
  }
}

@media screen and (min-width: 992px) {
  .comvc .menu {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .info-course .text-right .course-details-required {
    margin-top: 0;
  }
  .caps-list .chapter-name {
    width: 285px;
  }
  .item-reaction-holder .panel .panel-body {
    width: 79%;
    padding-left: 18px;
  }
  .ead-page .video-holder .modal {
    width: 900px;
  }
  .ead-page .information-area .tags-area {
    width: 49%;
    margin-bottom: 55px;
    float: left;
  }
  .ead-page .information-area .last-course {
    float: right;
    width: 34%;
    padding-left: 20px;
  }
  .ead-page .information-area .uc-course .uc-title {
    margin-bottom: 45px;
  }
  .ead-page .search-area {
    display: block;
    width: 480px;
    left: 240px;
    right: 0;
  }
  .search-results {
    border-top: 1px solid #F1F1F1;
    top: -1px;
    z-index: 10;
  }
  .search-area-desktop .custom-search-form .search-results {
    width: 585px;
    top: auto;
  }
  .ead-page .search-area .search-icon {
    top: 3px;
    font-size: 18px;
  }
  .ead-page .select-holder .select-role {
    width: 180px;
  }
  .ead-page .modal-exercise {
    margin: auto;
  }
  #modal-pre-test .modal-content {
    width: 900px;
  }
  #modal-evaluate .modal-content {
    width: 900px;
  }
}

@media screen and (min-width: 1200px) {
  .content-area .area-banner .banners-slider {
    padding-right: 0;
  }
  .content-area .area-banner .banners-slider img {
    width: 100%;
  }
  .content-area .area-banner .banners-slider .owl-item img {
    width: 100%;
    padding-right: 5px;
  }
  .event-gallery .event-image-list li.event-image {
    flex-basis: 23.75%;
  }
  .container-fluid {
    max-width: 1170px;
  }
  #modal-evaluate .modal-content {
    width: 900px;
  }
  #modal-pre-test .modal-content {
    width: 900px;
  }
  .item-reaction-holder .panel .panel-body {
    width: 82%;
  }
  .modal.modal-exercise {
    overflow-y: hidden;
  }
  .ead-page .modal-exercise {
    margin: auto;
  }
  .ead-page .information-area .tags-area {
    float: left;
    width: 50%;
    margin-bottom: 20px;
    padding-bottom: 2px;
  }
  .ead-page .information-area .status-course {
    width: 100%;
    padding-left: 20px;
  }
  .ead-page .information-area .status-course > * {
    display: inline-block;
    margin-right: 15px;
  }
  .ead-page .information-area .status-course > *:last-child {
    margin-right: 5px;
  }
  .ead-page .information-area .status-course h2 {
    text-transform: uppercase;
  }
  .ead-page .courses-done {
    margin-bottom: 13px;
  }
  .ead-page .courses-approved {
    margin-bottom: 13px;
  }
  .ead-page .courses-disapproved {
    margin-bottom: 15px;
  }
  .ead-page.comvc-page .information-area .status-course {
    width: auto;
    padding-left: 0px;
  }
  .ead-page.comvc-page .information-area .status-course > * {
    display: block;
    margin-right: 0px;
  }
  .ead-page.comvc-page .information-area .status-course > *:last-child {
    margin-right: 0;
  }
  .ead-page.comvc-page .information-area .status-course h2 {
    text-transform: uppercase;
  }
  .ead-page.comvc-page .courses-done {
    margin-bottom: 4px;
  }
  .ead-page.comvc-page .courses-approved {
    margin-bottom: 4px;
  }
  .ead-page.comvc-page .courses-disapproved {
    margin-bottom: 0px;
  }
  .ead-page .information-area .uc-course .uc-title {
    line-height: 20px;
    margin-bottom: 14px;
  }
  .ead-page .information-area .last-course {
    width: 35%;
  }
  .ead-page .header-elements:before {
    width: 2000px;
  }
  .ead-page .header-elements:after {
    width: 2000px;
  }
  .ead-page .banners-slider {
    padding-right: 8px;
  }
  .ead-page .area-banner .banner {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1200px) {
  .info-course .text-right {
    width: 66%;
  }
  .header-holder .header-elements .nav-holder {
    position: relative;
  }
  .header-holder .menu-area {
    right: 0;
    width: 925px;
    top: -68px;
  }
  .ead-page .search-area {
    left: 300px;
    top: -61px;
    width: 568px;
  }
  .ead-page .header-holder .name-holder {
    top: -88px;
  }
  .accordion > .position-panel.panel-chapter {
    top: -90px;
  }
  .video-holder .descr-video {
    width: 63%;
  }
  .panel-md-flex .panel-body {
    width: 82%;
  }
  .video-holder .hipervideo {
    top: 0px;
    right: 0px;
  }
  .video-holder .hipervideo .hiperlink {
    width: 300px;
    height: 270px;
  }
  .panel-info-course {
    height: 263px;
  }
  .holder-chapters {
    max-height: 140px;
  }
  .action-holder .panel-group {
    margin-bottom: 95px;
  }
  .panel-info-course > .panel-body .descr-course {
    width: 45%;
  }
  .panel-info-course > .panel-body .info-course {
    float: right;
    width: 50%;
  }
  .panel-lg-flex {
    display: flex;
    justify-content: space-between;
  }
  .panel-lg-flex .info-area {
    padding: 0 20px;
  }
  .panel-lg-flex .info-area .pre-test {
    float: left;
    margin-top: 10px;
    margin-bottom: 0;
  }
  .panel-lg-flex .info-area .text-right {
    float: left;
    margin-top: 10px;
    margin-left: 100px;
  }
  .panel-flex .info-area {
    width: 80%;
    text-align: left;
  }
  .panel-flex .panel-body .pre-test {
    float: none;
    margin-right: 0;
    margin-left: 0;
  }
  .panel-item-course .info-course {
    width: 66.3%;
  }
  .final-evaluation-holder .panel-opacity .panel-body .text-default {
    float: left;
    margin-right: 41px;
    margin-left: 260px;
  }
  .panel-evaluate-certificate .info-area {
    width: calc(100% - 242px);
    margin-left: 74px;
  }
  .pre-test-holder .panel-title {
    float: left;
  }
  .pre-test-holder .panel-bordered-success.panel .info-area {
    margin-left: 96px;
  }
  .pre-test-holder .pre-test {
    float: left;
    margin-bottom: 0;
  }
  .pre-test-holder .text-right {
    margin-top: -5px;
  }
  .certificate-holder .panel-flex .panel-body .pre-test {
    float: left;
    margin-left: 8px;
  }
  .certificate-holder .panel-flex .info-area .text-right {
    float: right;
    margin-top: 10px;
  }
  .certificate-holder .panel-flex .info-area .text-right .btn {
    margin-top: 0;
    margin-bottom: 0;
  }
  .panel .panel-body.info-area .evaluate-reproved {
    margin-right: 110px;
  }
  .final-evaluation-holder .panel-flex.panel-bordered-danger .info-area {
    width: 100%;
    margin-left: 53px;
  }
  .video-holder > iframe {
    height: 475px;
  }
  .video-holder > iframe.scorm-course {
    height: 870px;
  }
  .item-practice-holder .panel .info-area {
    width: 82%;
  }
  .video-holder:after {
    height: 215px;
  }
  .certificate-holder .panel-flex .info-area {
    width: 82.8%;
  }
  .info-course-holder .img-course {
    height: 262px;
  }
  .panel-flex .panel-body .evaluate-margin-left {
    float: left;
    width: 77%;
  }
  .pre-test-holder .panel-bordered-danger .info-area {
    margin-left: 96px;
  }
  .final-evaluation-holder .panel-bordered-success.panel-icon.panel-flex.evaluate-unterminated .info-area {
    margin-left: 0;
    padding-left: 0;
  }
}

@media all and (min-width: 1200px) {
  .pre-test-completed {
    float: left !important;
  }
}

@media all and (max-width: 1200px) {
  div.last-access {
    top: 60px;
    padding-right: 10px;
  }
}

@media all and (min-width: 991px) {
  #detailed-pre-test-results .modal-dialog {
    width: 991px;
  }
  .modal-dialog {
    width: auto;
  }
}

@media all and (max-width: 990px) {
  .test-results .mobile {
    display: block;
  }
  .test-results .desktop {
    display: none;
  }
  .test-results .no-padding {
    padding: 0 !important;
  }
}

body .login-container {
  background-image: url("../../images/welearn/bg-login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

body .login-container .content-login {
  background-color: white;
  padding: 30px 20px;
  border-radius: 10px;
}

body .login-container .content-login .image-holder {
  margin: 0 0 30px;
}

body .login-container .content-login .login-form-holder {
  width: unset;
}

body .login-container .content-login .login-form-holder > form .form-group {
  margin-bottom: 30px;
}

body .login-container .content-login .login-form-holder > form .form-group .btn {
  background-color: #1ea1db;
  border-color: #1ea1db;
  border-radius: 10px !important;
  font-weight: 400;
}

body .login-container .content-login .login-form-holder > form .form-group .btn:hover {
  background-color: transparent;
  color: #1ea1db;
}

body .login-container .content-login .login-form-holder > form .form-group .form-control {
  border: 1px solid #1ea1db;
  border-radius: 10px;
  color: #1ea1db;
  padding: 14px 30px;
}

body .login-container .content-login .login-form-holder > form .form-group .form-control::placeholder {
  color: #96a1a6;
}

body .login-container .content-login .login-form-holder > form .form-group .label-control {
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  color: #1ea1db;
}

body .login-container .content-login .login-form-holder > form .form-group .forgot-password {
  font-size: 12px;
  width: 100%;
  line-height: 17px;
  color: #1ea1db;
  display: block;
  text-align: center;
}

body .login-container .content-login .login-form-holder > form .form-group .first-access {
  font-size: 16px;
}

body .login-container .content-login .login-form-holder > form .form-group .register {
  font-size: 14px;
  width: 100%;
  line-height: 17px;
  color: #1ea1db;
  text-align: center;
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  body .login-container .content-login {
    padding: 60px 136px 60px 106px;
  }
}

body.ead-page .admin-actions {
  background: #0b4873;
}

body.ead-page .header-holder {
  background-color: white !important;
}

body.ead-page .header-holder .header-elements .top-bar {
  height: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 19px 0;
}

body.ead-page .header-holder .header-elements .top-bar .logo-holder {
  position: relative;
  left: 0;
  top: 0;
  min-width: 264px;
  margin-top: 0;
  margin-bottom: 20px;
}

body.ead-page .header-holder .header-elements .top-bar .logo-holder .logo-img {
  width: 100%;
  margin-bottom: 0;
  height: unset;
}

body.ead-page .header-holder .header-elements .top-bar .logo-holder .logo-img .logo {
  width: 100%;
  height: 52px;
  background: url("../../images/welearn/logo-header.png") no-repeat;
  background-size: contain;
}

body.ead-page .header-holder .header-elements .top-bar .search-area {
  min-width: 270px;
  margin-bottom: 30px;
  position: relative;
  left: 0;
  top: 0;
  z-index: 1000;
}

body.ead-page .header-holder .header-elements .top-bar .search-area .searchbox > .input-group.custom-search-form input {
  border: unset;
  border-bottom: 1px solid #1ea1db !important;
  margin-bottom: 2px;
  color: #1ea1db !important;
}

body.ead-page .header-holder .header-elements .top-bar .search-area .searchbox .search-results {
  position: absolute;
  top: 100%;
}

body.ead-page .header-holder .header-elements .top-bar .search-area .search-icon {
  color: #1ea1db !important;
}

body.ead-page .header-holder .header-elements .top-bar .name-holder {
  position: relative;
  top: 0;
  right: 0;
}

body.ead-page .header-holder .header-elements .top-bar .name-holder .text {
  color: #808080 !important;
  font-weight: 400;
  line-height: 17px;
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  body.ead-page .header-holder .header-elements .top-bar {
    flex-direction: row;
    justify-content: space-between;
  }
  body.ead-page .header-holder .header-elements .top-bar .search-area {
    margin-bottom: 0;
  }
  body.ead-page .header-holder .header-elements .top-bar .logo-holder {
    margin-bottom: 0;
  }
}

body .footer {
  background-color: white;
}

body .footer .container > div.footer-main-logo {
  flex: 0 0 100%;
  max-width: 100%;
  flex-direction: row;
  display: flex;
}

body .links-footer {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
}

body .links-foote-link {
  margin-top: 8px;
}

body .body-single-course {
  background-color: #3d3e40;
  padding: 10px 0 0;
  min-height: calc(100vh - 120px);
}

body .body-single-course .content-course {
  display: flex;
  flex-direction: column;
}

body .body-single-course .content-course .sidebar-course-holder {
  border-radius: 3px;
  padding: 0 20px;
  background-color: #fff;
}

body .body-single-course .content-course .sidebar-course-holder .sidebar-item .sidebar-item-header {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

body .body-single-course .content-course .sidebar-course-holder .sidebar-item .sidebar-item-content {
  padding: 10px 0;
}

@media screen and (min-width: 1024px) {
  body .body-single-course .content-course {
    margin-top: 30px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  body .body-single-course .content-course .descr-video {
    width: 64%;
  }
  body .body-single-course .content-course .sidebar-course-holder {
    width: 36%;
  }
}

body .training-category .category-title {
  position: relative;
  margin-bottom: 42px;
  z-index: 1;
  font-size: 18px;
  line-height: 24px;
  width: fit-content;
  color: white;
  padding: 2px 125px 2px 15px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: #1ea1db;
  border-radius: 10px;
}

body .training-category .category-title:before {
  position: absolute;
  left: -26px;
  z-index: -1;
  height: 59.5px;
  display: block;
  top: -12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 80px;
  background-image: url("../../images/welearn/before-title.png");
}

@media screen and (min-width: 1024px) {
  body .training-category .category-title {
    margin-left: 26px;
  }
  body .training-category .category-title:before {
    content: '';
  }
}

/**
 * Mixins
 */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.content-holder {
  position: relative;
}

.renewal-holder {
  margin-bottom: 30px;
}

@media screen and (min-width: 1200px) {
  .container-fluid {
    max-width: 1200px;
  }
}

.palette-text-primary {
  color: #d33597 !important;
}

.palette-text-primary-lighten {
  color: #e589c2 !important;
}

.palette-text-primary-darken {
  color: #851d5e !important;
}

.palette-text-primary-negative {
  color: #310b23 !important;
}

.palette-bg-primary {
  background-color: #d33597 !important;
}

.palette-bg-primary-lighten {
  background-color: #e589c2 !important;
}

.palette-bg-primary-darken {
  background-color: #851d5e !important;
}

.palette-bg-primary-negative {
  background-color: #310b23 !important;
}

.palette-border-primary {
  border-color: #d33597 !important;
}

.palette-border-primary-lighten {
  border-color: #e589c2 !important;
}

.palette-border-primary-darken {
  border-color: #851d5e !important;
}

.palette-border-primary-negative {
  border-color: #310b23 !important;
}

.palette-text-secondary {
  color: #2a4ea5 !important;
}

.palette-text-secondary-lighten {
  color: #5f82d6 !important;
}

.palette-text-secondary-darken {
  color: #152854 !important;
}

.palette-text-secondary-negative {
  color: #b0c1eb !important;
}

.palette-bg-secondary {
  background-color: #2a4ea5 !important;
}

.palette-bg-secondary-lighten {
  background-color: #5f82d6 !important;
}

.palette-bg-secondary-darken {
  background-color: #152854 !important;
}

.palette-bg-secondary-negative {
  background-color: #b0c1eb !important;
}

.palette-border-secondary {
  border-color: #2a4ea5 !important;
}

.palette-border-secondary-lighten {
  border-color: #5f82d6 !important;
}

.palette-border-secondary-darken {
  border-color: #152854 !important;
}

.palette-border-secondary-negative {
  border-color: #b0c1eb !important;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.col, .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 768px) {
  .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 992px) {
  .col, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1200px) {
  .col, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

h1.title-comvc, h2.title-comvc, h3.title-comvc, h4.title-comvc, h5.title-comvc, h6.title-comvc {
  color: #000;
}

p.text-comvc, span.text-comvc {
  color: #000;
}

p.text-tree-description, span.text-tree-description {
  font-size: 10px;
  padding: 0 15px;
  color: #5B422D;
}

p.text-tree-description.text-position-bottom, span.text-tree-description.text-position-bottom {
  width: 280px;
  position: absolute;
  text-align: right;
  bottom: 15px;
  right: 15px;
  margin-bottom: 0px;
}

@font-face {
  font-family: 'icomoon';
  src: url("../../fonts/icomoon.eot?wrv51o");
  src: url("../../fonts/icomoon.eot?wrv51o#iefix") format("embedded-opentype"), url("../../fonts/icomoon.ttf?wrv51o") format("truetype"), url("../../fonts/icomoon.woff?wrv51o") format("woff"), url("../../fonts/icomoon.svg?wrv51o#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-objective:before {
  content: "\e914";
}

.icon-question:before {
  content: "\e900";
}

.icon-map-pin-route:before {
  content: "\e901";
}

.icon-info-icon:before {
  content: "\e902";
}

.icon-arrow-chev-right:before {
  content: "\e903";
}

.icon-search-track:before {
  content: "\e904";
}

.icon-plus-track:before {
  content: "\e905";
}

.icon-arrow-down-track:before {
  content: "\e906";
}

.icon-close-track:before {
  content: "\e907";
}

.icon-audio:before {
  content: "\e908";
}

.icon-next-podcast:before {
  content: "\e909";
}

.icon-play-bar:before {
  content: "\e90a";
}

.icon-play:before {
  content: "\e90b";
}

.icon-prev-podcast:before {
  content: "\e90c";
}

.icon-volume:before {
  content: "\e90d";
}

.icon-star-full:before {
  content: "\e90e";
}

.icon-star-empty:before {
  content: "\e90f";
}

.icon-pause-bar:before {
  content: "\e910";
}

.icon-pause:before {
  content: "\e911";
}

.icon-compare_arrows:before {
  content: "\e912";
}

.icon-arrow_upward:before {
  content: "\e913";
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

textarea,
input,
select,
button {
  outline: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

p {
  font-size: 1em;
}

.clear-fix:before {
  content: '';
  clear: both;
  display: table;
}

.clear-fix:after {
  content: '';
  clear: both;
  display: table;
}

/**
 * Components
 */
.login-container {
  min-height: 100vh;
  background-color: #b3b3b3;
  padding: 0 15px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-container .image-holder {
  margin: 0px 0 50px;
  text-align: center;
}

.login-container .image-holder img {
  max-width: 299px;
  width: 100%;
  height: auto;
}

.login-container .logo-bottom-holder {
  text-align: center;
  margin: 50px 0 0;
}

.login-container .logo-bottom-holder img {
  max-width: 200px;
  height: auto;
}

@media screen and (min-width: 768px) {
  .login-container .image-holder {
    margin: 0px 0 50px;
  }
  .login-container .logo-bottom-holder {
    margin: 90px 0 0;
  }
}

.login-form-holder {
  margin: 0 auto;
  width: 254px;
}

.login-form-holder > form .form-group {
  margin-bottom: 15px;
}

.login-form-holder > form .form-group .form-control {
  width: 100%;
  color: white;
  padding: 10px;
  font-size: 15px;
  line-height: 20px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #9B9B9B;
}

.login-form-holder > form .form-group .form-control::placeholder {
  color: #EAE2D8;
}

.login-form-holder > form .form-group .btn {
  background-color: #D5454A;
  border: 1px solid #D5454A;
  border-radius: 0px !important;
  color: #FFF;
  text-transform: uppercase;
  font-size: 18px;
  display: block;
  width: 100%;
  text-align: center;
  line-height: 24px;
  padding: 8px 0;
  font-weight: 300;
  transition: all .3s linear;
}

.login-form-holder > form .form-group .btn:hover {
  color: #D5454A;
  background-color: #FFF;
}

.login-form-holder > form .form-group:last-child {
  margin-bottom: 0px;
}

.help-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 0px;
  background-color: #F97070;
  color: #FFF;
  text-align: center;
}

.help-block strong {
  font-weight: 600;
}

.header-page {
  background-color: #FFF;
}

.header-page .open-mobile-menu-holder {
  float: right;
  position: relative;
  top: 49px;
}

.information-holder {
  clear: both;
  margin-bottom: 15px;
}

.information-holder .information-basic {
  padding-right: 25px;
  float: left;
}

.information-holder .information-basic .avatar-user {
  float: left;
}

.information-holder .information-basic .avatar-user .avatar {
  display: inline-block;
}

.information-holder .information-basic .avatar-user .avatar .avatar-img {
  width: 51px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.information-holder .information-basic .information-user {
  float: left;
  margin-top: 5px;
  margin-left: 15px;
}

.information-holder .information-basic .information-user .username {
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  float: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 14px;
}

.information-holder .information-basic .information-user .username a {
  color: #161616;
}

.information-holder .information-basic .information-user .level {
  color: #DA0020;
  font-size: 12px;
  font-weight: bold;
}

.information-holder .information-basic .information-user .level .level-emblem {
  background: none;
}

.information-holder .information-basic .information-user .level .level-emblem.rubi {
  background: url("../../images/sprite.png") no-repeat -814px -67px;
  width: 9px;
  height: 9px;
}

.information-holder .information-basic .information-user .level .level-emblem.rubi-plus {
  background: url("../../images/sprite.png") no-repeat -814px -82px;
  width: 19px;
  height: 9px;
}

.information-holder .information-basic .information-user .level .level-emblem.esmeralda {
  background: url("../../images/sprite.png") no-repeat -814px -97px;
  width: 9px;
  height: 9px;
}

.information-holder .information-basic .information-user .level .level-emblem.esmeralda-plus {
  background: url("../../images/sprite.png") no-repeat -814px -112px;
  width: 19px;
  height: 9px;
}

.information-holder .information-basic .information-user .level .level-emblem.diamante {
  background: url("../../images/sprite.png") no-repeat -814px -127px;
  width: 9px;
  height: 9px;
}

.information-holder .information-basic .information-user .level .level-emblem.diamante-duplo {
  background: url("../../images/sprite.png") no-repeat -814px -142px;
  width: 19px;
  height: 9px;
}

.information-holder .information-basic .information-user .level .level-emblem.diamante-triplo {
  background: url("../../images/sprite.png") no-repeat -814px -157px;
  width: 29px;
  height: 9px;
}

.information-holder .information-basic .information-user .level .level-emblem.diamante-triplo-red {
  background: url("../../images/sprite.png") no-repeat -814px -157px;
  width: 29px;
  height: 9px;
}

.information-holder .information-basic .information-user .level .level-emblem.royal {
  background: url("../../images/sprite.png") no-repeat -814px -157px;
  width: 29px;
  height: 9px;
}

.information-holder .information-basic .information-user .level .level-emblem.imperador {
  background: url("../../images/sprite.png") no-repeat -814px -157px;
  width: 29px;
  height: 9px;
}

.information-holder .information-basic .information-user .level .level-emblem.diamante-triplo-black {
  background: url(../../images/sprite.png) no-repeat -814px -186px;
  width: 29px;
  height: 10px;
}

.information-holder .information-basic .information-user .level .level-emblem.diamante-triplo-imperial, .information-holder .information-basic .information-user .level .level-emblem.diamante-triplo-crown, .information-holder .information-basic .information-user .level .level-emblem.diamante-triplo-royal {
  background: url("../../images/sprite.png") no-repeat -814px -157px;
  width: 29px;
  height: 9px;
}

.information-holder .logout {
  float: right;
  padding: 5px 0 4px 25px;
  border-left: 1px solid #DA0020;
}

.information-holder .logout .logout-button {
  text-align: center;
  display: inline-block;
  font-size: 14px;
  color: #DA0020;
}

.information-holder .logout .logout-button .icon {
  background: url("../../images/sprite.png") no-repeat -821px -18px;
  width: 20px;
  height: 23px;
  margin-bottom: 5px;
}

@media screen and (min-width: 768px) {
  .header-page .top-header {
    position: relative;
    height: 180px;
  }
  .information-holder {
    clear: none;
    float: right;
    margin-top: 72px;
    margin-bottom: 0;
  }
}

.mobile-menu {
  display: block !important;
}

.mobile-menu .menu-close {
  display: none;
  background: #DA0020;
  padding: 20px 0;
}

.mobile-menu .menu-close .nav-menu li .menu-item {
  margin-bottom: 20px;
  display: block;
  color: #FFF;
  font-size: 16px;
}

.mobile-menu .menu-close .nav-menu li .menu-item .icon-submenu {
  transform: rotate(0deg);
  float: right;
  transition: transform .3s ease-in-out;
}

.mobile-menu .menu-close .nav-menu li .menu-item .icon-submenu i {
  vertical-align: middle;
}

.mobile-menu .menu-close .nav-menu li .menu-item .icon-submenu.submenu-active {
  transform: rotate(180deg);
}

.mobile-menu .menu-close .nav-menu li .submenu {
  display: none;
  margin-bottom: 20px;
  padding: 0 15px;
}

.mobile-menu .menu-close .nav-menu li .submenu li .submenu-item {
  font-size: 14px;
  color: #FFF;
  margin-bottom: 20px;
  display: block;
}

.mobile-menu .menu-close .nav-menu li .submenu li:last-child .submenu-item {
  margin-bottom: 0;
}

.mobile-menu .menu-alt {
  display: flex;
  justify-content: space-around;
  background: #DD0020;
  padding: 15px 0;
}

.mobile-menu .menu-alt .colmn-50 {
  flex-basis: 50%;
  text-align: center;
}

.mobile-menu .menu-alt .colmn-50 .menu-item-icon {
  color: #FFF;
  font-size: 16px;
}

.mobile-menu .menu-alt .colmn-50 .menu-item-icon .icon {
  background: url("../../images/sprite.png") no-repeat;
  background-position: 0px 0px;
  width: 35px;
  height: 35px;
  margin: 0 auto 5px;
}

.mobile-menu .menu-alt .colmn-50 .menu-item-icon span {
  color: #FFF;
}

.mobile-menu .menu-alt .colmn-50 .menu-item-icon.capture .icon {
  width: 37px;
  height: 37px;
  background-position: -324px -58px;
}

.mobile-menu .menu-alt .colmn-50 .menu-item-icon.conference .icon {
  width: 31px;
  height: 37px;
  background-position: -388px -58px;
}

.desktop-menu {
  display: none;
}

.desktop-menu .menu-holder {
  background: #DD0020;
  height: 75px;
}

.desktop-menu .menu-holder .menu-list {
  position: relative;
  width: 642px;
  margin: 0 auto;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item {
  position: relative;
  display: inline-block;
  padding: 10px 7px 8px;
  height: 75px;
  transition: background-color .3s ease-in-out;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item .icon-holder {
  width: 60px;
  height: 53px;
  margin: 0 8px;
  background: url("../../images/sprite-2.png") no-repeat;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item .icon-holder.training {
  display: inline-block;
  width: 73px;
  background-position: -1px -58px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item .icon-holder.podcasts {
  display: inline-block;
  width: 51px;
  background-position: -89px -174px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item .icon-holder.events {
  display: inline-block;
  background-position: -81px -58px;
  width: 42px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item .icon-holder.data {
  display: inline-block;
  background-position: -129px -58px;
  width: 63px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item .icon-holder.tools {
  display: inline-block;
  background-position: -198px -58px;
  width: 68px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item .icon-holder.myoffice {
  display: inline-block;
  background-position: -270px -58px;
  width: 49px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item .icon-holder.capture {
  display: inline-block;
  background-position: -322px -58px;
  width: 42px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item .icon-holder.conference {
  display: inline-block;
  background-position: -371px -57px;
  width: 64px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item .icon-holder.search {
  display: inline-block;
  background-position: -440px -57px;
  width: 38px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item .icon-holder.search-active {
  width: 38px;
  background-position: -440px 1px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item .icon-holder.faq {
  display: inline-block;
  height: 56px;
  width: 24px;
  background-position: -14px -173px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item.menu-item-dropdown {
  cursor: pointer;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item.search-on {
  background: #FFF;
  padding: 10px 7px 8px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item.search-on:before {
  opacity: 1;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item:before {
  content: '';
  width: 100%;
  height: 8px;
  display: inline-block;
  background: #000;
  position: absolute;
  top: -8px;
  left: 0;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  pointer-events: none;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item:hover {
  padding: 10px 7px 8px;
  background: #FFF;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item:hover:before {
  opacity: 1;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item:hover .icon-holder.training {
  background-position: -1px 1px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item:hover .icon-holder.podcasts {
  display: inline-block;
  width: 51px;
  background-position: -147px -174px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item:hover .icon-holder.events {
  background-position: -81px 1px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item:hover .icon-holder.data {
  background-position: -128px 1px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item:hover .icon-holder.tools {
  background-position: -197px 1px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item:hover .icon-holder.myoffice {
  background-position: -268px 1px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item:hover .icon-holder.capture {
  background-position: -322px 1px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item:hover .icon-holder.conference {
  background-position: -371px 1px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item:hover .icon-holder.search {
  background-position: -440px 1px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item:hover .icon-holder.faq {
  background-position: -49px -172px;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li.submenu-item-open {
  position: relative;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li.submenu-item-open .submenu-desktop {
  opacity: 0;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 87px;
  width: 155px;
  left: -30px;
  background: #FFF;
  padding: 10px;
  border-radius: 5px;
  z-index: -1;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li.submenu-item-open .submenu-desktop li .submenu-item {
  margin-bottom: 7px;
  padding-bottom: 7px;
  border-bottom: 1px solid #DD0020;
  display: block;
  font-size: 14px;
  color: #DD0020;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li.submenu-item-open .submenu-desktop li:last-child .submenu-item {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li.submenu-item-open .submenu-desktop:before {
  content: '';
  width: 0;
  height: 0;
  border-bottom: 12px solid #FFF;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li.submenu-item-open .submenu-desktop.submenu-open {
  opacity: 1;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li.submenu-item-open .submenu-desktop.submenu-open:before {
  opacity: 1;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li.submenu-item-open:hover .submenu-desktop {
  display: block;
  opacity: 1;
  z-index: 10;
}

.desktop-menu .menu-holder .menu-list .main-menu .nav-menu li.submenu-item-open:hover .submenu-desktop:before {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
  .desktop-menu {
    display: block;
  }
  .open-mobile-menu-holder {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .desktop-menu .menu-holder .menu-list {
    width: 692px;
  }
  .desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item {
    padding: 10px 10px 8px;
  }
  .desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item:hover {
    padding: 10px 10px 8px;
  }
  .desktop-menu .menu-holder .menu-list .main-menu .nav-menu li .menu-item.search-on {
    padding: 10px 10px 8px;
  }
  .desktop-menu .menu-holder .menu-list .main-menu .nav-menu li.submenu-item-open .submenu-desktop {
    left: -25px;
  }
}

.hamburger-mobile {
  padding: 0px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger-mobile > .box {
  width: 20px;
  height: 18px;
  display: inline-block;
  position: relative;
}

.hamburger-mobile > .box > .inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 19px;
  height: 1px;
  background-color: #FFF;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-mobile > .box > .inner:before, .hamburger-mobile > .box > .inner:after {
  content: "";
  display: block;
  width: 19px;
  height: 1px;
  background-color: #FFF;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-mobile > .box > .inner:before {
  top: -6px;
}

.hamburger-mobile > .box > .inner:after {
  bottom: -6px;
}

.hamburger-mobile.hamburger-animation > .box > .inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger-mobile.hamburger-animation > .box > .inner:before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger-mobile.hamburger-animation > .box > .inner:after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger-mobile.hamburger-animation.active > .box > .inner {
  transform: rotate(90deg);
  background-color: transparent;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger-mobile.hamburger-animation.active > .box > .inner:before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger-mobile.hamburger-animation.active > .box > .inner:after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.search-area.search-area-mobile {
  border-top: 3px solid #DA0020;
  border-bottom: 3px solid #DA0020;
  padding: 15px 0;
  width: 100%;
  position: static;
}

.search-area.search-area-mobile .input-search {
  margin-right: 10px;
  color: #DA0020;
  border: 0;
  border-bottom: 1px solid #DA0020 !important;
  padding: 5px 10px;
  font-size: 14px;
  width: calc(100% - 57px);
}

.search-area.search-area-mobile .input-search::placeholder {
  color: #DA0020;
  font-weight: 400;
}

.search-area.search-area-mobile .input-search.form-control {
  display: inline-block;
}

.search-area.search-area-mobile .search-submit i {
  font-size: 24px;
  color: #DA0020;
  font-weight: bold;
  vertical-align: middle;
}

.search-area.search-area-desktop {
  opacity: 0;
  transition: all .3s ease-in-out;
  position: absolute;
  width: 0;
  top: 0;
  left: -30px;
  right: auto;
  background: #dd0020;
  padding: 21px 0 20px;
  align-items: center;
}

.search-area.search-area-desktop .input-search {
  font-size: 16px;
  color: #FFF;
  border: 0;
  width: 96%;
  max-height: 0px;
  transition: max-height .3s ease-in-out;
  border-bottom: 1px solid #FFF !important;
  background: transparent;
  padding: 0;
}

.search-area.search-area-desktop .input-search::placeholder {
  color: #FFF;
}

.search-area.search-area-desktop.search-area-active {
  opacity: 1;
  width: 100%;
}

.search-area.search-area-desktop.search-area-active .input-search {
  max-height: 100px;
  padding: 7px 10px;
}

.logo-holder-comvc {
  margin-bottom: 20px;
  float: left;
  width: 85%;
}

.logo-holder-comvc h1 .logo {
  display: inline-block;
  width: 212px;
  height: 109px;
  background: url("../../src/images/logo.png") no-repeat;
  background-size: contain;
  text-indent: -9999px;
}

@media screen and (min-width: 768px) {
  .logo-holder-comvc {
    width: 213px;
    margin-top: 33px;
    margin-bottom: 0;
  }
  .logo-holder-comvc h1 {
    margin: 0;
  }
}

@media screen and (min-width: 1024px) {
  .logo-holder-comvc {
    margin-top: 0;
    position: absolute;
    top: 33px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.button {
  color: #000;
  border: 1px solid #000;
  border-radius: 3px;
  padding: 6px 10px;
  display: inline-block;
  font-size: 14px;
}

.button.button-danger {
  color: #FFF;
  background: #FF1921;
  border: 1px solid #FF1921;
}

.button.button-search {
  color: #88B58D;
  padding: 10px 20px 10px 15px;
  background-color: #F5F5F5;
  border: none;
  border-radius: none;
  cursor: pointer;
}

.button.button-search > .icon-search {
  font-size: 15px;
  color: #88B58D;
}

@media (min-width: 455px) {
  .button.button-search > .icon-search {
    width: 29px;
    height: 29px;
  }
}

@media (min-width: 1024px) {
  .button.button-search {
    margin-left: 4px;
    padding: 10px 19px;
  }
}

@media (min-width: 1278px) {
  .button.button-search {
    margin-left: 6px;
  }
}

.select-track {
  position: relative;
}

.select-track select {
  padding: 10px 0;
  width: 100%;
  padding-left: 20px;
  padding-right: 46px;
  cursor: pointer;
  background-color: #F5F5F5;
  border: none;
  color: #5B422D;
}

.select-track:after {
  content: "\e902";
  font-size: 6px;
  position: absolute;
  top: calc(50% - 3px);
  right: 20px;
  pointer-events: none;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5B422D;
}

@media (min-width: 1200px) {
  .select-track select {
    padding-left: 21px;
  }
  .select-track:after {
    right: 22px;
  }
}

.hamburger {
  background: #FFF;
  width: 28px;
  height: 28px;
  position: relative;
  bottom: 2px;
  border-radius: 50%;
  padding: 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: 0;
}

.hamburger-box {
  width: 17px;
  height: 11px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 17px;
  height: 3px;
  background-color: #DA0020;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -5px;
}

.hamburger-inner::after {
  bottom: -5px;
}

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.panel-comvc {
  border: 1px solid #e5e5e5;
  padding: 20px;
  border-radius: 3px;
  background: #FFF;
}

.panel-comvc .colmn-left {
  float: left;
}

.panel-comvc .colmn-right {
  float: right;
}

.panel-comvc.expirate-panel .expirate-date {
  text-align: center;
  margin-bottom: 30px;
}

.panel-comvc.expirate-panel .expirate-date .info-circle {
  margin-right: 10px;
  background: #FF1921;
  border-radius: 50%;
  color: #FFF;
  border: 3px solid #000;
  width: 41px;
  margin: 0 auto 10px;
  height: 41px;
  font-size: 23px;
  text-align: center;
  line-height: 38px;
}

.panel-comvc.expirate-panel .expirate-date .info-circle span {
  color: #FFF;
}

.panel-comvc.expirate-panel .expirate-date .expirate-info .expirate-title {
  margin: 0;
  font-size: 17px;
  color: #205751;
}

.panel-comvc.expirate-panel .expirate-date .expirate-info .expirate-descr {
  font-size: 14px;
  color: #2B8076;
}

.panel-comvc.expirate-panel .renewal-now {
  text-align: center;
  width: 100%;
}

.panel-comvc.panel-danger {
  border: 1px solid #FF1921;
}

@media screen and (min-width: 768px) {
  .panel-comvc.expirate-panel .expirate-date {
    text-align: left;
    margin-bottom: 0;
  }
  .panel-comvc.expirate-panel .expirate-date .info-circle {
    margin: 0;
    margin-right: 10px;
    float: left;
  }
  .panel-comvc.expirate-panel .expirate-date .expirate-info {
    float: left;
    margin-top: -1px;
  }
  .panel-comvc.expirate-panel .expirate-date .expirate-info .expirate-title {
    font-size: 20px;
    font-weight: 400;
  }
  .panel-comvc.expirate-panel .expirate-date .expirate-info .expirate-descr {
    font-size: 14px;
  }
  .panel-comvc.expirate-panel .renewal-now {
    margin-top: 3px;
    width: auto;
  }
}

.training-category .category-title {
  margin-bottom: 42px;
  font-size: 18px;
  line-height: 24px;
  color: #2a4ea5;
  font-weight: 400;
}

.training-category .category-title .icon {
  background-size: contain;
  background-position: center left;
  background-repeat: no-repeat;
  width: 39px;
  height: 45px;
  display: inline-block;
  position: relative;
  top: 4px;
}

.footer .logo-foot {
  max-width: 100%;
}

.footer .container > div {
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
}

.footer .container > div.footer-alt-logo {
  margin-bottom: 30px;
}

.footer .container > div.footer-alt-logo .logo {
  display: inline-block;
}

.footer .container > div.footer-alt-logo .logo:not(:last-child) {
  margin-right: 30px;
}

@media screen and (min-width: 768px) {
  .footer {
    padding: 52px 0;
  }
  .footer .container {
    justify-content: space-around;
    position: relative;
  }
  .footer .container > div.footer-main-logo {
    flex: 0 0 170px;
    max-width: 170px;
    position: absolute;
  }
  .footer .container > div.footer-alt-logo {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 auto;
    justify-content: space-around;
    display: flex;
  }
  .footer .container > div.footer-alt-logo .logo:not(:last-child) {
    margin-right: 0px;
  }
  .footer .container > div.footer-alt-logo img {
    width: 100%;
    height: auto;
  }
}

.social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.social .social-item {
  background-image: url("../../images/sprite.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 51px;
  height: 51px;
  margin: 0 10px;
}

.social .social-item:last-child {
  margin: 0 10px 0 0;
}

.social.social-small .social-item {
  transform: scale(0.8);
}

.social .whatsapp {
  background-position: -492px 0;
}

.social .instagram {
  background-position: -545px 0;
}

.social .twitter {
  background-position: -598px 0;
}

.social .facebook {
  background-position: -651px 0;
}

.social .linkedin {
  background-position: -704px 0;
}

.social .google-plus {
  background-position: -757px 0;
}

.btn-big {
  font-size: 24px;
  padding: 10px 30px;
}

.no-comments {
  text-align: center;
}

.no-comments h4 {
  margin-bottom: 30px;
  font-weight: normal;
  color: #666 !important;
}

.rating-header {
  margin: 30px 0;
  display: flex;
}

.rating-header .left-side {
  width: 200px;
  border-right: 1px solid #e5e5e5;
  padding: 5px;
}

.rating-header .right-side {
  width: 100%;
  margin-left: 30px;
}

.rating-header-content {
  text-align: center;
  width: 100%;
}

.rating-header-content .rating-value {
  font-size: 80px;
  line-height: 60px;
  margin-bottom: 30px;
}

.rating-header-content .rating-total {
  opacity: .7;
}

.rating-stars {
  margin: 10px auto;
  text-align: center;
  cursor: default;
}

.rating-stars .rating-select {
  font-size: 16px;
}

.rating-stars select {
  display: none;
}

.rating-stars.star-left {
  text-align: left;
}

.rating-stars .br-theme-fontawesome-stars .br-widget {
  height: 20px;
}

.rating-stars .br-theme-fontawesome-stars .br-readonly a {
  font-size: 16px;
  margin: 0 2px;
}

.rating-stars .br-theme-fontawesome-stars .br-widget a.br-selected:after,
.rating-stars .br-theme-fontawesome-stars .br-widget a.br-active:after {
  color: #f8e81c;
}

.graph-color-5 {
  background-color: #8BC34A;
  width: 100%;
}

.graph-color-4 {
  background-color: #B8E986;
  width: 20%;
}

.graph-color-3 {
  background-color: #F8E81C;
  width: 10%;
}

.graph-color-2 {
  background-color: #F6A623;
  width: 10%;
}

.graph-color-1 {
  background-color: #EF5350;
  width: 5%;
}

.rating-graph {
  width: 100%;
  color: #9B9B9B;
}

.rating-graph .graph-item {
  display: flex;
  align-items: center;
}

.rating-graph .graph-label {
  margin-right: 10px;
  font-size: 10px;
  width: 35px;
}

.rating-graph .graph-label .star-item {
  font-size: 16px;
  margin-right: 2px;
}

.rating-graph .graph-result {
  position: relative;
  margin: 5px 0;
  width: 100%;
}

.rating-graph .graph-result .graph-value {
  position: relative;
  z-index: 2;
  color: #111;
  width: 100%;
  padding: 2px 5px;
  font-size: 10px;
  text-align: left;
}

.rating-graph .graph-result .graph-progress {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.comments-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.comments-header .comment-title {
  font-size: 16px;
  line-height: 50px;
  margin-right: 40px;
}

.comments-content {
  display: flex;
  margin: 0 -10px;
  flex-wrap: wrap;
}

.comment-item {
  position: relative;
  width: 50%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  transition: all .2s;
}

.comment-item:hover {
  border-color: #eee;
}

.comment-item .comment-user {
  color: #2B425B;
  font-size: 16px;
}

.comment-item .comment-date {
  text-transform: lowercase;
  color: #758697;
  font-size: 13px;
}

.comment-item .comment-rating {
  text-align: left;
}

.comment-item .comment {
  position: relative;
}

.comment-item .comment .comment-title {
  color: #2B425B;
  font-size: 18px;
  margin-bottom: 10px;
}

.comment-item .comment .comment-content {
  color: #9b9b9b;
  font-size: 14px;
}

.load-more {
  cursor: pointer;
  color: #9b9b9b;
  font-size: 14px;
  padding: 10px;
  text-align: center;
  transition: all .2s;
}

.load-more:hover {
  background-color: #f1f1f1;
}

.label-info {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px 10px;
  border: 1px solid #8BC34A;
  background-color: #fff;
  color: #8BC34A;
}

@media screen and (max-width: 768px) {
  .comment-item {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .btn-big {
    font-size: 14px;
  }
  .rating-stars .star-item {
    margin: 0;
  }
  .rating-header .right-side {
    margin: 10px;
  }
  .comments-header {
    display: block;
    text-align: center;
  }
  .comments-header .comment-title {
    margin-right: 0;
  }
}

.rating-select-one .br-wrapper {
  cursor: pointer;
}

.rating-select-one .br-wrapper .br-widget a.br-active:after, .rating-select-one .br-wrapper .br-widget a.br-selected:after {
  color: #d2d2d2;
}

.rating-select-one .br-wrapper.active .br-widget a.br-selected:after, .rating-select-one .br-wrapper:hover .br-widget a.br-selected:after {
  color: #f8e81c;
}

.rating-select-one a {
  pointer-events: none;
}

.rating-stars.required-error > .control-label:after {
  content: "*Selecione uma avaliação";
  color: red;
  margin-left: 10px;
}

.comment-answer-holder {
  padding-left: 20px;
  border-left: 1px solid #e5e5e5;
}

.comment-answer-holder > .comment-answer-text {
  display: block;
  color: #9b9b9b;
}

.comment-answer-holder > .comment-answer-date {
  margin: 15px 0 0;
  font-weight: 600;
  text-align: right;
  display: block;
  color: #9b9b9b;
}

@media screen and (min-width: 768px) {
  .comment-answer-holder {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .comment-answer-holder > .comment-answer-text {
    flex: 1 1 100%;
    max-width: 100%;
  }
  .comment-answer-holder > .comment-answer-date {
    margin: 0;
    flex: 0 0 120px;
    max-width: 120px;
    text-align: right;
  }
}

.comment .title-answer-rating {
  margin: 15px 0 5px;
  font-weight: 700;
  display: block;
  color: #2B425B;
}

.faq {
  margin: 30px auto 120px;
}

.faq .title {
  margin-bottom: 30px;
}

.faq-list {
  position: relative;
}

.faq-list .panel-title {
  position: relative;
  cursor: pointer;
  z-index: 10;
}

.faq-list.active .panel-body {
  max-height: 500px;
  opacity: 1;
}

.faq-list.active .icon-collapse {
  transform: rotate(90deg);
}

.faq-list .faq-answer {
  padding: 20px;
  word-wrap: break-word;
}

.faq-list .icon-collapse {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 18px;
  transition: all .2s;
}

.faq-list .panel-body {
  max-height: 0;
  opacity: 0;
  padding: 0;
  transition: all .6s;
  overflow: hidden;
}

.body-single-course {
  background-color: #EAE2D8;
  min-height: 100vh;
}

.body-single-course .container-fluid > .header-holder .logo-holder {
  text-align: center;
  float: none;
  margin-bottom: 30px;
}

.body-single-course .container-fluid > .header-holder .logo-holder .title-logo {
  margin-bottom: 0px;
}

.body-single-course .container-fluid > .header-holder .logo-holder .title-logo .logo {
  width: 168px;
  height: 91px;
}

.body-single-course .container-fluid > .header-holder .exit-holder {
  margin-bottom: 30px;
  text-align: right;
  float: none;
}

.body-single-course .container-fluid > .header-holder .exit-holder a {
  color: #000;
  font-size: 15px;
  line-height: 20px;
}

.body-single-course .container-fluid > .header-holder .exit-holder a i {
  font-size: 16px;
  vertical-align: middle;
  margin-right: 6px;
  position: relative;
  top: -2px;
}

.body-single-course .container-fluid > .video-holder iframe {
  margin-bottom: 30px;
  height: 250px;
}

.body-single-course .container-fluid > .video-holder .descr-video > h2 {
  font-size: 28px;
  color: #000;
  line-height: 38px;
  margin: 0 0 15px;
}

.body-single-course .container-fluid > .video-holder .descr-video > p {
  color: #000;
  font-size: 15px;
  line-height: 20px;
}

.body-single-course .container-fluid > .sidebar-course-holder > .sidebar-item {
  margin-bottom: 10px;
}

.body-single-course .container-fluid > .sidebar-course-holder > .sidebar-item > .sidebar-item-header {
  margin: 0;
  color: #000;
  font-size: 18px;
  line-height: 24px;
  padding: 10px 0;
  border-bottom: 1px solid #9B9B9B;
  font-weight: 400;
}

.body-single-course .container-fluid > .sidebar-course-holder > .sidebar-item > .sidebar-item-header i {
  margin-right: 3px;
  font-size: 16px;
}

.body-single-course .container-fluid > .sidebar-course-holder > .sidebar-item > .sidebar-item-content {
  padding: 10px 0 10px;
}

.body-single-course .container-fluid > .sidebar-course-holder > .sidebar-item > .sidebar-item-content .list-content .list-item {
  font-size: 14px;
  color: #4A4A4A;
  line-height: 29px;
}

.body-single-course .container-fluid > .sidebar-course-holder > .sidebar-item > .sidebar-item-content .list-content .list-item a {
  color: #4A4A4A;
}

.body-single-course .container-fluid > .sidebar-course-holder > .sidebar-item > .sidebar-item-content .list-content .list-item i {
  font-size: 13px;
}

.body-single-course .container-fluid > .sidebar-course-holder > .sidebar-item > .sidebar-item-content .list-content .list-item .time {
  float: none;
  font-weight: 700;
}

.body-single-course .container-fluid > .sidebar-course-holder > .sidebar-item > .sidebar-item-content .list-content .list-item.list-item-tip > p {
  font-size: 12px;
  line-height: 17px;
  color: #4A4A4A;
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
  .body-single-course .container-fluid > .header-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    margin: 0px 0 30px;
    padding-top: 15px;
  }
  .body-single-course .container-fluid > .header-holder:before, .body-single-course .container-fluid > .header-holder:after {
    display: none;
  }
  .body-single-course .container-fluid > .header-holder .logo-holder {
    margin: 0;
    text-align: left;
  }
  .body-single-course .container-fluid > .header-holder .exit-holder {
    margin: 0;
  }
  .body-single-course .container-fluid > .video-holder iframe {
    height: 425px;
  }
}

@media screen and (min-width: 1024px) {
  .body-single-course .container-fluid {
    display: flex;
    flex-wrap: wrap;
  }
  .body-single-course .container-fluid > .header-holder {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .body-single-course .container-fluid > .video-holder {
    flex: 0 0 73%;
    max-width: 73%;
    margin-top: 0px;
    padding-right: 30px;
  }
  .body-single-course .container-fluid > .video-holder iframe {
    height: 430px;
  }
  .body-single-course .container-fluid > .video-holder .descr-video {
    float: none;
    width: 670px;
  }
  .body-single-course .container-fluid > .sidebar-course-holder {
    flex: 0 0 27%;
    max-width: 27%;
  }
  .body-single-course .container-fluid > .sidebar-course-holder > .sidebar-item:first-child > .sidebar-item-header {
    padding-top: 0px;
  }
}

@media screen and (min-width: 1280px) {
  .body-single-course .container-fluid {
    display: flex;
    flex-wrap: wrap;
  }
  .body-single-course .container-fluid > .video-holder {
    flex: 0 0 900px;
    max-width: 900px;
  }
  .body-single-course .container-fluid > .video-holder iframe {
    height: 430px;
  }
  .body-single-course .container-fluid > .sidebar-course-holder {
    flex: 0 0 270px;
    max-width: 270px;
  }
}

.banner-holder {
  margin-top: 30px;
  margin-bottom: 40px;
}

.banner-holder .banners-slider .banner-image img {
  width: 100%;
  height: auto;
}

.banner-holder .banners-slider .banner-image .mobile-image {
  display: block;
}

.banner-holder .banners-slider .banner-image .tablet-landscape-image {
  display: none !important;
}

.banner-holder .banners-slider .banner-image .tablet-portrait-image {
  display: none !important;
}

.banner-holder .banners-slider .banner-image .desktop-image {
  display: none !important;
}

@media screen and (min-width: 768px) and (max-height: 1280px) {
  .banner-holder .banners-slider .banner-image .mobile-image {
    display: none !important;
  }
  .banner-holder .banners-slider .banner-image .tablet-landscape-image {
    display: none !important;
  }
  .banner-holder .banners-slider .banner-image .tablet-portrait-image {
    display: block !important;
  }
  .banner-holder .banners-slider .banner-image .desktop-image {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) and (max-height: 860px) {
  .banner-holder .banners-slider .banner-image .mobile-image {
    display: none !important;
  }
  .banner-holder .banners-slider .banner-image .tablet-landscape-image {
    display: block !important;
  }
  .banner-holder .banners-slider .banner-image .tablet-portrait-image {
    display: none !important;
  }
  .banner-holder .banners-slider .banner-image .desktop-image {
    display: none !important;
  }
}

@media screen and (min-width: 1280px) {
  .banner-holder .banners-slider .banner-image .mobile-image {
    display: none !important;
  }
  .banner-holder .banners-slider .banner-image .tablet-landscape-image {
    display: none !important;
  }
  .banner-holder .banners-slider .banner-image .tablet-portrait-image {
    display: none !important;
  }
  .banner-holder .banners-slider .banner-image .desktop-image {
    display: block !important;
  }
}

.banner-holder .banner-track {
  display: block;
  background: transparent;
  background: -moz-linear-gradient(top, transparent 25%, #ebe5d0 100%);
  background: -webkit-linear-gradient(top, transparent 25%, #ebe5d0 100%);
  background: linear-gradient(to bottom, transparent 25%, #ebe5d0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='transparent', endColorstr='#ebe5d0',GradientType=0 );
  padding: 21px 45px;
}

.banner-holder .logo-tracks {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../images/logo-trilhas-large.png");
  width: 100%;
  max-width: 211px;
  min-height: 120px;
  height: 140px;
  max-height: 88px;
  margin: 0 auto 48px;
}

.banner-holder .info-banner {
  text-align: center;
}

.banner-holder .info-banner p {
  font-size: 12px;
  color: #6C6C6C;
  line-height: 19px;
  margin-bottom: 25px;
}

.banner-holder .info-banner .button {
  background-color: transparent;
  border-color: #076A42;
  color: #076A42;
  border-radius: 26px;
  line-height: 33px;
  font-size: 18px;
  text-align: center;
  display: inline-block;
  padding: 3px 30px;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .banner-holder .banner-track {
    display: flex;
    align-items: center;
    padding: 21px 45px;
  }
  .banner-holder .logo-tracks {
    margin: 0 50px 0 0;
  }
  .banner-holder .info-banner {
    text-align: left;
  }
  .banner-holder .info-banner p {
    float: left;
    margin-right: 50px;
  }
  .banner-holder .info-banner .button {
    margin-bottom: 11px;
  }
}

@media screen and (min-width: 1024px) {
  .banner-holder .banner-track {
    position: relative;
  }
  .banner-holder .info-banner p {
    margin-bottom: 0px;
  }
  .banner-holder .info-banner .button {
    margin-bottom: 0px;
    position: relative;
    top: 9px;
  }
  .banner-holder .tree-tracks {
    background-image: url("../../images/tree-trilhas.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 220px;
    height: 140px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

@media screen and (min-width: 1280px) {
  .banner-holder .tree-tracks {
    width: 272px;
    height: 160px;
    right: 0;
  }
}

@media screen and (max-width: 767px) {
  .banner-holder .logo-tracks {
    min-height: 58px !important;
    height: 58px !important;
    max-height: 58px !important;
    margin: 0 auto 10px !important;
  }
  .banner-holder .info-banner p {
    font-size: 10px !important;
    margin-bottom: 10px !important;
  }
  .banner-holder .info-banner .button {
    padding: 3px 15px !important;
    line-height: 20px !important;
    font-size: 13px !important;
  }
}

.training-category-preview + .row {
  margin-bottom: 50px;
}

.preview-info-content .sprout-holder {
  width: 82px;
  height: 82px;
  margin: 0 auto 30px;
  background-image: url("../../images/sprout-image.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.preview-info-content .text-holder p {
  color: #949494;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0px;
}

.preview-info-content .text-holder p span {
  text-decoration: underline;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .preview-info-content {
    display: flex;
    align-items: center;
  }
  .preview-info-content .sprout-holder {
    margin: 0 30px 0 0;
  }
}

.career-content-holder {
  padding-top: 0px !important;
  height: 100vh;
  background: white;
  background: -moz-linear-gradient(top, white 25%, #ebe5d0 100%);
  background: -webkit-linear-gradient(top, white 25%, #ebe5d0 100%);
  background: linear-gradient(to bottom, white 25%, #ebe5d0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ebe5d0',GradientType=0 );
}

.career-content-holder .video-tutorial {
  height: 100%;
  width: 100%;
  background-color: #eee6d8;
  z-index: -1;
  opacity: 0;
  transition: all .3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: right;
}

.career-content-holder .video-tutorial .close-tutorial {
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 30px;
  border: 1px solid #544334;
  color: #FFF;
  background-color: #544334;
  margin: 8px 8px 0px 0;
  cursor: pointer;
}

.career-content-holder .video-tutorial iframe {
  height: 100vh !important;
  width: 100% !important;
  max-height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.career-content-holder .video-tutorial.show-tutorial {
  z-index: 18;
  opacity: 1;
}

.career-content-holder > div {
  height: 100%;
}

@media screen and (max-width: 767px) {
  .career-content-holder .video-loading > .video-bg {
    transform: scale(0.8);
  }
}

.career-content-holder .text {
  color: #888;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  text-align: left;
}

.career-content-holder .text.text-modal {
  color: #9B9B9B;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 20px;
  white-space: pre-line;
}

.career-content-holder .text.text-term {
  font-size: 10px;
  color: #666;
  line-height: 17px;
  position: absolute;
  bottom: 0px;
}

@media screen and (min-width: 768px) {
  .career-content-holder .text.text-term {
    padding-left: 10px;
    width: 250px;
  }
}

@media screen and (min-width: 1024px) {
  .career-content-holder .text.text-term {
    width: 450px;
  }
}

@media screen and (min-width: 1280px) {
  .career-content-holder .text.text-term {
    width: auto;
  }
}

.career-content-holder .title.title-description {
  color: #544334;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin: 0 0 2px;
}

.career-content-holder .title.title-description .see-info {
  margin-left: 5px;
  color: #544334;
  display: inline-block;
  position: relative;
  top: 1px;
  cursor: pointer;
}

.career-content-holder .title.title-description .see-info a {
  color: #544334;
}

.career-content-holder a {
  font-size: 14px;
  color: #FFF;
  line-height: 19px;
  font-weight: 300;
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.career-content-holder .button-track.button-large {
  font-size: 12px;
  padding: 19px 20px 19px 62px;
  line-height: 22px;
  border-radius: 30px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  display: block;
}

.career-content-holder .button-track.button-large.button-icon > i {
  font-size: 34px;
  color: #FFF;
  position: absolute;
  left: 7px;
  top: 5px;
  z-index: 10;
}

.career-content-holder .button-track.button-jobtitle {
  background-color: #076640;
  color: #FFF;
  border: 1px solid transparent;
  position: absolute;
  right: 15px;
  bottom: 82px;
  z-index: 10;
  padding: 10px 15px 10px 45px;
  text-align: right;
  font-size: 12px;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

.career-content-holder .button-track.button-jobtitle.button-icon > i {
  font-size: 30px;
  top: 5px;
  left: 10px;
}

.career-content-holder .button-track.button-jobtitle-disabled {
  background-color: rgba(7, 102, 64, 0.55) !important;
  border-color: transparent !important;
}

.career-content-holder .button-track.button-open-modal {
  cursor: pointer;
}

@media screen and (min-width: 410px) {
  .career-content-holder .button-track.button-large.button-icon > i {
    left: 10px;
  }
}

@media screen and (min-width: 768px) {
  .career-content-holder .button-track.button-large {
    display: block;
  }
  .career-content-holder .button-track.button-jobtitle {
    font-size: 16px;
    right: 15px;
    bottom: 11px;
  }
}

.career-content-holder .header-wrapper .logo-holder {
  font-size: 0px;
  margin: 0px;
  padding: 23px 0 0;
  position: absolute;
  left: 55px;
  z-index: 18;
}

.career-content-holder .header-wrapper .logo-holder .logo {
  display: inline-block;
  text-indent: -9999px;
  width: 94px;
  height: 39px;
  background-size: cover;
  background-position: center;
  background-position: center;
  background-image: url("../../images/logo-career-track.png");
}

.career-content-holder .header-wrapper .logo-holder.logo-over-side .logo {
  background-image: url("../../images/logo-career-track-white.png");
}

.career-content-holder .sidebar-wrapper {
  opacity: 0;
  transition: opacity .45s ease-in-out;
}

.career-content-holder .sidebar-wrapper .hamburger-mobile {
  position: absolute;
  z-index: 18;
  left: 11px;
  top: 30px;
}

.career-content-holder .sidebar-wrapper .sidebar-holder {
  display: inline-flex;
  position: absolute;
  transform: translate(-145px);
  transition: transform .3s ease-in-out;
  z-index: 8;
}

.career-content-holder .sidebar-wrapper .sidebar-holder .sidebar-content {
  background-color: #544334;
  position: relative;
  z-index: 14;
}

.career-content-holder .sidebar-wrapper .sidebar-holder .sidebar-content .menu-holder {
  margin: 114px 0 0 15px;
  opacity: 0;
  transition: all .25s ease-in-out;
  max-width: 130px;
}

.career-content-holder .sidebar-wrapper .sidebar-holder .sidebar-content .menu-holder li {
  margin-bottom: 15px;
}

.career-content-holder .sidebar-wrapper .sidebar-holder .sidebar-content .menu-holder li > a span {
  display: block;
  font-weight: 700;
}

.career-content-holder .sidebar-wrapper .sidebar-holder > a {
  position: absolute;
  bottom: 23px;
  left: 15px;
  opacity: 0;
  transition: all .25s ease-in-out;
  z-index: 14;
}

.career-content-holder .sidebar-wrapper .sidebar-holder.sidebar-active {
  transform: translate(0px);
  z-index: 15;
}

.career-content-holder .sidebar-wrapper .sidebar-holder.sidebar-active .sidebar-content .menu-holder {
  opacity: 1;
}

.career-content-holder .sidebar-wrapper .sidebar-holder.sidebar-active > a {
  opacity: 1;
}

.career-content-holder .sidebar-wrapper .sidebar-holder:after {
  content: '';
  display: block;
  width: 50px;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("../../images/side-career.png");
  transform-origin: bottom;
  transition: opacity .5s, transform .8s ease-in-out;
  position: relative;
  left: -1px;
}

.career-content-holder .sidebar-wrapper .sidebar-holder.sidebar-loaded:after {
  opacity: 1;
  transform: scaleY(1);
}

.career-content-holder .sidebar-wrapper .sidebar-overlay {
  z-index: 12;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 0;
  transition: opacity .3s ease-in-out;
  pointer-events: none;
}

.career-content-holder .sidebar-wrapper .sidebar-overlay.sidebar-active {
  pointer-events: all;
  opacity: 1;
}

.career-content-holder .sidebar-wrapper.display-sidebar {
  opacity: 1;
}

.career-content-holder .loading-wrapper {
  display: block;
  position: absolute;
  right: 50px;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  background-color: #F5F5F5;
}

.career-content-holder .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 34px;
  height: 34px;
  margin: 0 auto;
}

.career-content-holder .lds-ellipsis div {
  position: absolute;
  top: 17px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #291E15;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.career-content-holder .lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.career-content-holder .lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.career-content-holder .lds-ellipsis div:nth-child(3) {
  left: 18px;
  animation: lds-ellipsis2 0.6s infinite;
}

.career-content-holder .lds-ellipsis div:nth-child(4) {
  left: 30px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}

@keyframes appearLogo {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  95% {
    opacity: 0.9;
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.career-content-holder .body-content-holder {
  padding: 95px 0 0 55px;
  height: 100%;
  overflow: hidden;
}

.career-content-holder .body-content-holder .video-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transition: opacity .6s ease-in-out;
  pointer-events: none;
  z-index: 20;
  background-color: #eee6d8;
}

.career-content-holder .body-content-holder .video-loading .logo-holder {
  background-image: url("../../images/logo-loading.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 204px;
  height: 84px;
  position: absolute;
  top: 74px;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0;
  animation: appearLogo .6s linear 4.1s forwards;
}

.career-content-holder .body-content-holder .video-loading video {
  position: absolute;
  bottom: 21px;
  left: 0;
  right: 0;
  margin: auto;
}

.career-content-holder .body-content-holder .route-content-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 45px);
  padding-right: 45px;
  opacity: 0;
  transition: opacity .45s ease-in-out;
  padding-bottom: 60px;
}

.career-content-holder .body-content-holder .route-content-holder > .goal-step-item {
  flex: 0 0 65px;
  max-height: 65px;
}

.career-content-holder .body-content-holder .route-content-holder > .my-own-step-item {
  flex: 0 0 auto;
  max-height: 100%;
}

.career-content-holder .body-content-holder .route-content-holder .route-careers-holder {
  flex: 1 1 auto;
  min-height: 0;
  width: 100%;
  position: relative;
  display: flex;
}

.career-content-holder .body-content-holder .route-content-holder .route-careers-holder:before {
  content: '';
  height: 60px;
  width: 100%;
  position: absolute;
  top: -1px;
  right: 0;
  left: -18px;
  background: -moz-linear-gradient(top, #fcfbf8 0%, rgba(250, 249, 245, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, #fcfbf8 0%, rgba(250, 249, 245, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, #fcfbf8 0%, rgba(250, 249, 245, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfbf8', endColorstr='#00ffffff',GradientType=0 );
  z-index: 1;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  pointer-events: none;
}

.career-content-holder .body-content-holder .route-content-holder .route-careers-holder > .scroll-div-career-route {
  width: 100%;
  flex: 1 1 100%;
  min-height: 0;
  max-height: 100%;
  overflow: auto;
  margin: 0 -55px;
  padding: 0 43px 0 55px;
}

.career-content-holder .body-content-holder .route-content-holder .route-careers-holder > .div-career-route {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.career-content-holder .body-content-holder .route-content-holder .route-careers-holder > .div-career-route .small-tree-holder {
  background-image: url("../../images/tree-small.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 87px;
  height: 133px;
  margin: 0 auto 10px;
}

.career-content-holder .body-content-holder .route-content-holder .route-careers-holder > .div-career-route .text-holder {
  text-align: center;
  font-size: 12px;
  line-height: 19px;
  color: #076640;
}

.career-content-holder .body-content-holder .route-content-holder .route-careers-holder > .div-career-route .text-holder b {
  display: block;
  font-size: 14px;
}

.career-content-holder .body-content-holder .route-content-holder .route-careers-holder .step-item {
  flex: 0 0 auto;
  max-width: 100%;
  margin-bottom: 30px;
}

.career-content-holder .body-content-holder .route-content-holder .route-careers-holder .routes-track-step-item {
  flex: 0 0 67px;
  max-height: 67px;
  margin-bottom: 15px;
}

.career-content-holder .body-content-holder .route-content-holder .route-careers-holder.scroll-gradient:before {
  opacity: 1;
}

.career-content-holder .body-content-holder .route-content-holder .route-careers-holder.scroll-bottom-gradient:before {
  opacity: 1;
  transform: rotate(180deg);
  top: auto;
  bottom: -1px;
  background: -moz-linear-gradient(top, #f3efe1 0%, rgba(250, 249, 245, 0.5) 50%, rgba(241, 236, 221, 0) 100%);
  background: -webkit-linear-gradient(top, #f3efe1 0%, rgba(250, 249, 245, 0.5) 50%, rgba(241, 236, 221, 0) 100%);
  background: linear-gradient(to bottom, #f3efe1 0%, rgba(250, 249, 245, 0.5) 50%, rgba(241, 236, 221, 0) 100%);
}

.career-content-holder .body-content-holder .route-content-holder .level-content-holder {
  padding-left: 8px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.career-content-holder .body-content-holder .route-content-holder .level-content-holder > .scroll-area {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.career-content-holder .body-content-holder .route-content-holder .jobtitle-content-holder {
  flex: 0 0 100%;
  max-height: 100%;
  padding-bottom: 25px;
  width: 100%;
  overflow: visible;
  margin: 0 -17px 0 -55px;
}

.career-content-holder .body-content-holder .route-content-holder .jobtitle-content-holder .scroll-area {
  width: 100%;
  height: 100%;
  padding: 0 17px 0 55px;
  box-sizing: content-box;
  overflow-y: scroll;
}

.career-content-holder .body-content-holder .route-content-holder.route-entry-content-holder {
  padding-right: 15px;
}

.career-content-holder .body-content-holder .route-content-holder.route-entry-content-holder:after {
  display: none;
}

.career-content-holder .body-content-holder .route-content-holder.route-entry-content-holder > .general-step-item {
  flex: 0 0 auto;
  max-height: 100%;
  margin-bottom: 30px;
}

.career-content-holder .body-content-holder .route-content-holder.route-entry-content-holder .greetings {
  right: 15px;
}

.career-content-holder .body-content-holder .route-content-holder.route-entry-content-holder .route-careers-holder > .scroll-div-career-route {
  padding-right: 15px;
}

.career-content-holder .body-content-holder .route-content-holder.route-loaded {
  opacity: 1;
}

.career-content-holder .body-content-holder .route-content-holder.route-alternative-content-holder .route-careers-holder .scroll-div-career-route .step-item .jobtitles-list .jobtitle-item:not(:last-child) {
  margin-right: 6px;
}

.career-content-holder .body-content-holder .greetings {
  color: #076640;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 0px;
  position: absolute;
  right: 15px;
  top: 30px;
}

.career-content-holder .body-content-holder .greetings.front-greetings {
  opacity: 0;
  transition: opacity .45s ease-in-out;
}

.career-content-holder .body-content-holder .greetings.front-greetings.greetings-show {
  opacity: 1;
}

.career-content-holder .body-content-holder .greetings.all-routes {
  max-width: 200px;
  text-align: right;
}

.career-content-holder .body-content-holder .greetings.all-routes > i.unrotate-icon {
  transform: rotate(0deg);
  margin-left: 7px;
}

.career-content-holder .body-content-holder .greetings i {
  display: inline-block;
  transform: rotate(180deg);
  position: relative;
  right: 2px;
  font-size: 18px;
  top: 2px;
}

.career-content-holder .body-content-holder.body-content-loaded .video-loading {
  opacity: 0;
  z-index: -1;
}

@media screen and (max-width: 410px) {
  .career-content-holder .body-content-holder .greetings {
    font-size: 10px;
  }
  .career-content-holder .body-content-holder .greetings i {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .career-content-holder .body-content-holder .route-content-holder {
    padding-bottom: 30px;
  }
  .career-content-holder .body-content-holder .route-content-holder .route-careers-holder > .div-career-route .small-tree-holder {
    width: 117px;
    height: 183px;
    margin: 0 auto 15px;
  }
  .career-content-holder .body-content-holder .route-content-holder .route-careers-holder > .div-career-route .text-holder {
    font-size: 14px;
  }
  .career-content-holder .body-content-holder .route-content-holder .route-careers-holder > .div-career-route .text-holder b {
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) {
  .career-content-holder .body-content-holder:after {
    content: '';
    width: 650px;
    height: 825px;
    display: block;
    background-image: url(../../images/tree-background.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    position: fixed;
    bottom: -50px;
    right: 50px;
    z-index: 0;
    pointer-events: none;
  }
  .career-content-holder .body-content-holder .route-content-holder {
    padding-bottom: 0px;
  }
}

.career-content-holder .button-step {
  border-radius: 13px;
  padding: 3px 10px;
  color: #FFF;
  background-color: #194C38;
  display: inline-block;
}

.career-content-holder .step-leaf-represent {
  background-image: url("../../images/leaf-routes.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex: 0 0 30px;
  height: 31px;
  display: inline-block;
  margin-right: 12px;
}

.career-content-holder .step-leaf-represent.specialist {
  background-image: url("../../images/leaf-specialist.svg");
}

.career-content-holder .step-leaf-represent.director {
  background-image: url("../../images/leaf-director.svg");
}

.career-content-holder .step-leaf-represent.manager {
  background-image: url("../../images/leaf-manager.svg");
}

.career-content-holder .step-leaf-represent.encharge {
  background-image: url("../../images/leaf-encharge.svg");
}

.career-content-holder .step-leaf-represent.supervisor {
  background-image: url("../../images/leaf-supervisor.svg");
}

.career-content-holder .step-info-holder {
  flex: 1 1 calc(100% - 42px);
  max-width: calc(100% - 42px);
}

.career-content-holder .step-info-holder .title {
  font-size: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 800;
  margin: 0;
  line-height: 32px;
  color: #D5454A;
}

.career-content-holder .step-info-holder .label-step {
  color: #5B422D;
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  display: block;
  margin-bottom: -3px;
}

.career-content-holder .step-info-holder .jobtitles-number {
  color: #999;
  line-height: 14px;
  font-size: 10px;
  margin-bottom: 5px;
  display: block;
}

.career-content-holder .step-info-holder .more-info {
  color: #D5454A;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  display: block;
  margin-top: -3px;
}

.career-content-holder .level-step-item {
  margin-bottom: 35px;
  display: block;
}

.career-content-holder .level-step-item .current-jobtitle {
  display: block;
  color: #5B422D;
  line-height: 17px;
  font-size: 12px;
  font-weight: 300;
}

.career-content-holder .level-step-item .title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 0px;
}

.career-content-holder .level-step-item .see-jobtitle {
  color: #4A4A4A;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
}

.career-content-holder .level-step-item.current .title {
  font-weight: 700;
  margin: 6px 0;
}

.career-content-holder .level-step-item:last-child {
  margin-bottom: 0px;
}

.career-content-holder .step-item {
  display: flex;
  position: relative;
}

.career-content-holder .step-item:before {
  content: '';
  display: block;
  width: 19px;
  height: 45px;
  background-image: url("../../images/step-before.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: -30px;
  left: -19px;
}

.career-content-holder .step-item.general-step-item:before {
  bottom: auto;
  left: -18px;
  top: 36px;
}

.career-content-holder .step-item.general-step-item .step-info-holder .title {
  display: block;
  font-weight: 400;
  margin-bottom: 12px;
  line-height: 34px;
  font-size: 18px;
}

.career-content-holder .step-item.general-step-item .step-info-holder .jobtitles-list {
  display: flex;
  flex-wrap: wrap;
}

.career-content-holder .step-item.general-step-item .step-info-holder .jobtitles-list .jobtitle-item {
  margin-bottom: 5px;
}

.career-content-holder .step-item.general-step-item .step-info-holder .jobtitles-list .jobtitle-item > .button-step {
  font-size: 10px;
}

.career-content-holder .step-item.general-step-item .step-info-holder .jobtitles-list .jobtitle-item > .relation-holder {
  margin: 0 5px 0 3px;
  position: relative;
  font-size: 10px;
}

.career-content-holder .step-item.goal-step-item {
  align-items: center;
  margin-bottom: 30px;
}

.career-content-holder .step-item.my-own-step-item {
  margin-top: 30px;
}

.career-content-holder .step-item.my-own-step-item:before {
  bottom: -51px;
  left: -14px;
}

.career-content-holder .step-item.my-own-step-item .step-leaf-represent {
  background-image: url("../../images/leaf-my-jobtitle.svg");
  flex: 0 0 44px;
  max-width: 44px;
  height: 45px;
}

.career-content-holder .step-item.my-own-step-item .step-info-holder {
  flex: 0 0 calc(100% - 56px);
  max-width: calc(100% - 56px);
}

.career-content-holder .step-item.my-own-step-item .step-info-holder .title {
  color: #076640;
}

.career-content-holder .step-item.routes-track-step-item .step-leaf-represent {
  flex: 0 0 39px;
  max-width: 39px;
  height: 32px;
}

.career-content-holder .step-item.routes-track-step-item .step-info-holder {
  flex: 0 0 calc(100% - 51px);
  max-width: calc(100% - 51px);
}

.career-content-holder .step-item.routes-track-step-item .step-info-holder .title {
  color: #194C38;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.career-content-holder .step-item.routes-track-step-item .step-info-holder .label-step {
  color: #999;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 5px;
  font-weight: 400;
  display: block;
}

.career-content-holder .step-item.routes-track-step-item:before {
  bottom: -12px;
}

.career-content-holder .step-item.routes-track-step-item:nth-child(odd) {
  justify-content: flex-end;
}

.career-content-holder .step-item.routes-track-step-item:nth-child(odd):before {
  left: auto;
  right: -9px;
  transform: rotateY(180deg);
}

.career-content-holder .step-item.routes-track-step-item:nth-child(odd) .step-leaf-represent {
  order: 2;
  margin-left: 12px;
  margin-right: 0px;
}

.career-content-holder .step-item.routes-track-step-item:nth-child(odd) .step-info-holder {
  order: 1;
  text-align: right;
}

.career-content-holder .step-item.my-track-step-item .step-leaf-represent {
  flex: 0 0 34px;
  max-width: 34px;
  height: 34px;
}

.career-content-holder .step-item.my-track-step-item .step-info-holder .title {
  margin-bottom: 0px;
}

.career-content-holder .step-item.my-own-step-level {
  align-items: center;
}

.career-content-holder .step-item.my-own-step-level:before {
  top: 80px;
  left: -18px;
}

.career-content-holder .step-item.my-own-step-level .step-leaf-represent {
  flex: 0 0 45px;
  max-width: 45px;
  height: 45px;
}

.career-content-holder .step-item.my-own-step-level .step-info-holder .title {
  margin-bottom: 0px;
  font-weight: 800;
}

@media screen and (min-width: 410px) {
  .career-content-holder .step-item .step-info-holder .title {
    font-size: 23px !important;
  }
  .career-content-holder .step-item.my-own-step-item:before {
    bottom: -49px;
  }
  .career-content-holder .step-item.routes-track-step-item .step-info-holder .title {
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) {
  .career-content-holder .step-item.my-own-step-level:before {
    left: -18px;
  }
}

.career-content-holder .goal-holder-text {
  margin-bottom: 30px;
}

.career-content-holder .goal-holder-text > .goal-step-item {
  margin-bottom: 10px;
}

.career-content-holder .goal-holder-text > p {
  color: #666;
  font-size: 12px;
}

.career-content-holder .goal-holder-text > p span {
  height: 18px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  color: #FFF;
  font-size: 12px;
  line-height: 18px;
  margin: 0 2px;
  padding: 0 6px;
}

.career-content-holder .jobtitle-header {
  display: flex;
  position: relative;
  margin-bottom: 40px;
}

.career-content-holder .jobtitle-header:before {
  content: '';
  display: block;
  width: 19px;
  height: 45px;
  background-image: url("../../images/step-before.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 60px;
  left: -19px;
}

.career-content-holder .jobtitle-header .jobtitle-leaf {
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../images/leaf-my-jobtitle.svg");
  height: 44px;
  flex: 0 0 45px;
  position: relative;
  top: 6px;
}

.career-content-holder .jobtitle-header .jobtitle-leaf.specialist {
  background-image: url("../../images/leaf-specialist.svg");
}

.career-content-holder .jobtitle-header .jobtitle-leaf.director {
  background-image: url("../../images/leaf-director.svg");
}

.career-content-holder .jobtitle-header .jobtitle-leaf.manager {
  background-image: url("../../images/leaf-manager.svg");
}

.career-content-holder .jobtitle-header .jobtitle-leaf.encharge {
  background-image: url("../../images/leaf-encharge.svg");
}

.career-content-holder .jobtitle-header .jobtitle-leaf.supervisor {
  background-image: url("../../images/leaf-supervisor.svg");
}

.career-content-holder .jobtitle-header .header-info .title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  margin: 0 0 10px;
}

.career-content-holder .jobtitle-header .header-info .cbo {
  font-size: 10px;
}

@media screen and (min-width: 410px) {
  .career-content-holder .jobtitle-header:before {
    left: -17px;
  }
}

@media screen and (min-width: 768px) {
  .career-content-holder .jobtitle-header:before {
    left: -12px;
  }
}

@media screen and (min-width: 1024px) {
  .career-content-holder .jobtitle-header:before {
    left: -17px;
  }
}

.career-content-holder .description-text-holder:not(:last-child) {
  margin-bottom: 15px;
}

.career-content-holder .description-text-holder.description-list-holder {
  margin-bottom: 30px;
}

.career-content-holder .description-text-holder.description-list-holder .title {
  margin: 0 0 5px;
}

.career-content-holder .description-text-holder.description-list-holder .title .descr-icon {
  display: inline-block;
  margin-right: 8px;
}

.career-content-holder .description-text-holder.description-list-holder .title .descr-icon i {
  font-size: 18px;
  position: relative;
  top: 2px;
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list {
  display: flex;
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list .leaf-before {
  flex: 0 0 12px;
  height: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../images/leaf-my-jobtitle.svg");
  position: relative;
  top: 5px;
  margin-right: 10px;
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list .leaf-before.specialist {
  background-image: url("../../images/leaf-specialist.svg");
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list .leaf-before.director {
  background-image: url("../../images/leaf-director.svg");
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list .leaf-before.manager {
  background-image: url("../../images/leaf-manager.svg");
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list .leaf-before.encharge {
  background-image: url("../../images/leaf-encharge.svg");
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list .leaf-before.supervisor {
  background-image: url("../../images/leaf-supervisor.svg");
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list .text {
  margin-bottom: 0px;
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list .text .level {
  display: block;
  font-weight: 700;
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list:not(:last-child) {
  margin-bottom: 20px;
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list.item-list-check .before-item {
  flex: 0 0 16px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../images/item-checked.svg");
  position: relative;
  top: 4px;
  margin-right: 7px;
  opacity: 0;
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list.item-list-check .text {
  color: #C7C7C7;
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list.item-list-check.competenceChecked .before-item {
  opacity: 1;
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list.item-list-check.competenceChecked .text {
  color: #888;
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list.item-list-jobtitles {
  width: 100%;
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list.item-list-jobtitles .info-jobtitle span {
  display: block;
  color: #666;
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list.item-list-jobtitles .info-jobtitle span.name {
  font-size: 16px;
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list.item-list-jobtitles .info-jobtitle span.level {
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
}

.career-content-holder .description-text-holder.description-list-holder .list-holder .item-list.item-list-jobtitles .info-jobtitle span.see-more {
  font-weight: 300;
  font-size: 14px;
}

.career-content-holder .description-text-holder.relational-jobtitles {
  border-top: 1px dashed #888;
  padding-top: 30px;
}

.career-content-holder .description-text-holder.relational-jobtitles .list-holder .item-list .leaf-before {
  flex: 0 0 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.career-content-holder .description-text-holder.relational-jobtitles .list-holder .item-list .leaf-before i {
  color: #FFF;
  font-size: 14px;
}

.career-content-holder .modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9998;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .5s ease-in-out;
  background-color: rgba(235, 229, 208, 0.8);
}

.career-content-holder .modal:before {
  display: none;
}

.career-content-holder .modal .layer-close-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.career-content-holder .modal .modal-holder {
  margin: 0 20px;
  border-radius: 15px;
  max-width: 390px;
  width: auto;
  background-color: white;
  border: 1px solid #544334;
  transform: translateY(0%);
  transition: all .45s ease-in-out;
}

.career-content-holder .modal .modal-content {
  padding: 20px;
  border-radius: 15px;
}

.career-content-holder .modal .modal-content .modal-body {
  padding: 0;
}

.career-content-holder .modal .modal-content .modal-body .modal-header-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.career-content-holder .modal .modal-content .modal-body .modal-header-info > i {
  margin-right: 8px;
  font-size: 22px;
  color: #544334;
}

.career-content-holder .modal .modal-content .modal-body .modal-header-info > .descr-holder {
  color: #544334;
  line-height: 18px;
  text-align: left;
  font-size: 13px;
}

.career-content-holder .modal .modal-content .modal-body .modal-header-info > .descr-holder span {
  font-weight: 700;
  display: block;
}

.career-content-holder .modal .modal-content .modal-body .modal-body-info .close-modal {
  color: #544334;
  font-size: 12px;
  line-height: 17px;
  text-decoration: underline;
  display: block;
  text-align: center;
}

.career-content-holder .modal.modal-use-term {
  overflow: hidden;
}

.career-content-holder .modal.modal-use-term .modal-holder {
  max-height: 95vh;
  max-width: 600px;
}

.career-content-holder .modal.modal-use-term .modal-holder .modal-content {
  max-height: 95vh;
  overflow: auto;
}

.career-content-holder .modal.modal-use-term .modal-holder .modal-content .modal-body .modal-title {
  font-size: 20px;
  color: #076640;
  margin-bottom: 20px;
}

.career-content-holder .modal.modal-use-term .modal-holder .modal-content .modal-body .text-container .text {
  margin-bottom: 15px;
}

.career-content-holder .modal.modal-use-term .modal-holder .modal-content .modal-body .agree-check .checkbox-holder {
  text-align: center;
}

.career-content-holder .modal.modal-use-term .modal-holder .modal-content .modal-body .agree-check .checkbox-holder label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.career-content-holder .modal.modal-use-term .modal-holder .modal-content .modal-body .agree-check .checkbox-holder label span {
  text-align: left;
  color: #5B422D;
  font-size: 16px;
  font-weight: 500;
}

.career-content-holder .modal.modal-use-term .modal-holder .modal-content .modal-body .agree-check .checkbox-holder label span.box-input {
  height: 20px;
  width: 20px;
  flex: 0 0 20px;
  margin-right: 10px;
  border: 1px solid #5B422D;
  position: relative;
}

.career-content-holder .modal.modal-use-term .modal-holder .modal-content .modal-body .agree-check .checkbox-holder label span.box-input:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background-color: #5B422D;
  transform: scale(0);
  transition: transform .4s ease;
}

.career-content-holder .modal.modal-use-term .modal-holder .modal-content .modal-body .agree-check .checkbox-holder label input:checked + .box-input:before {
  transform: scale(1);
}

.career-content-holder .modal.modal-tutorial .modal-holder {
  max-width: 450px;
}

.career-content-holder .modal.modal-tutorial .modal-content .logo-holder {
  width: 135px;
  height: 52px;
  background-image: url("../../images/logo-track.png");
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto 30px;
}

.career-content-holder .modal.modal-tutorial .modal-content .text {
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
}

.career-content-holder .modal.modal-tutorial .modal-content .actions-holder .button-tutorial {
  background-color: #d5454a;
  border-radius: 30px;
  color: #FFF;
  border-color: #d5454a;
  font-size: 16px;
  display: inline-block;
  padding: 7px 18px;
  font-weight: 400;
}

.career-content-holder .modal.modal-tutorial .modal-content .actions-holder .skip-tutorial {
  color: #076640;
  font-weight: 300;
  font-size: 12px;
  display: block;
  margin-top: 10px;
}

@media screen and (min-width: 410px) {
  .career-content-holder .modal .modal-holder {
    margin: 0 63px;
  }
  .career-content-holder .modal.modal-use-term .modal-holder {
    margin: 0 30px;
  }
  .career-content-holder .modal.modal-tutorial .modal-content {
    padding: 20px 40px;
  }
}

.career-content-holder .modal-enter {
  opacity: 1;
}

.career-content-holder .modal-leave-active {
  opacity: 0;
}

.career-content-holder .modal-enter .modal-holder,
.career-content-holder .modal-leave-active .modal-holder {
  opacity: 0;
  transform: translateY(-30%);
}

body.comvc-page {
  color: #000;
}

body.comvc-page h1, body.comvc-page h2, body.comvc-page h3, body.comvc-page h4, body.comvc-page h5, body.comvc-page h6 {
  color: #000;
}

body.comvc-page p, body.comvc-page span {
  color: #000;
}

body.comvc-page .descr-video p, body.comvc-page .descr-video span {
  color: #FFF;
}

.pagination li > a, .pagination li > span {
  margin: 0 6px;
  padding: 7px 14px;
  border-radius: 3px !important;
}

.pagination li > a:hover {
  border-color: #ef5350;
  color: #ef5350;
  box-shadow: inset 0 0 1px #ef5350;
}

.pagination > .active > span {
  background-color: #ee4a47;
  border-color: #ef5350;
}

.pagination > .active:hover > span {
  background-color: #ee525e;
  border-color: #ee525e;
}

.exit-holder {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 10px;
}

.banner {
  z-index: 9999;
  position: fixed;
  width: 100%;
  padding: 15px 10px;
  top: 0;
  background-color: white;
  align-items: center;
  border-bottom: 1px solid green;
}

.banner .content {
  display: flex;
}

.banner .message {
  width: 750px;
}

.banner .message p {
  color: black;
  font-size: 16px;
}

.banner .action {
  margin-left: auto;
}

.banner .action .btn {
  width: 100px;
}

@media screen and (max-width: 975px) {
  .banner .content {
    flex-direction: column;
    align-items: center;
  }
  .banner .message {
    width: inherit;
  }
  .banner .message p {
    font-size: 14px;
  }
  .banner .action {
    margin-left: inherit;
  }
}

.underlined-title2 {
  font-size: 30px;
  color: #008800;
  margin-bottom: 4%;
  border-bottom: 2px solid #008800;
}
