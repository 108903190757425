.login-container
{
    min-height: 100vh;
    background-color: #b3b3b3;
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image-holder
    {
        margin: 0px 0 50px;
        text-align: center;

        img
        {
            max-width: 299px;
            width: 100%;
            height: auto;
        }
    }

    .logo-bottom-holder
    {
        text-align: center;
        margin: 50px 0 0;

        img
        {
            max-width: 200px;
            height: auto;
        }
    }

    @media screen and (min-width: 768px)
    {
        .image-holder
        {
            margin: 0px 0 50px;
        }

        .logo-bottom-holder
        {
            margin: 90px 0 0;
        }
    }
}

.login-form-holder
{
    margin: 0 auto;
    width: 254px;

    > form
    {
        .form-group
        {
            margin-bottom: 15px;

            .form-control
            {
                width: 100%;
                color: white;
                padding: 10px;
                font-size: 15px;
                line-height: 20px;
                border: none;
                background-color: transparent;
                border-bottom: 1px solid #9B9B9B;

                &::placeholder
                {
                    color: #EAE2D8;
                }
            }

            .btn
            {
                background-color: #D5454A;
                border: 1px solid #D5454A;
                border-radius: 0px !important;
                color: #FFF;
                text-transform: uppercase;
                font-size: 18px;
                display: block;
                width: 100%;
                text-align: center;
                line-height: 24px;
                padding: 8px 0;
                font-weight: 300;
                transition: all .3s linear;

                &:hover
                {
                    color: #D5454A;
                    background-color: #FFF;
                }
            }

            &:last-child
            {
                margin-bottom: 0px;
            }
        }
    }
}

.help-block
{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 15px 0px;
    background-color: #F97070;
    color: #FFF;
    text-align: center;

    strong
    {
        font-weight: 600;
    }
}