.btn-big {
    font-size: 24px;
    padding: 10px 30px;
}

.no-comments {
    text-align: center;

    h4 {
        margin-bottom: 30px;
        font-weight: normal;
        color: #666!important;
    }
}

.rating-header {
    margin: 30px 0;
    display: flex;

    .left-side {
        width: 200px;
        border-right: 1px solid #e5e5e5;
        padding: 5px;
    }

    .right-side {
        width: 100%;
        margin-left: 30px;
    }
}

.rating-header-content {
    text-align: center;
    width: 100%;
    
    .rating-value {
        font-size: 80px;
        line-height: 60px;
        margin-bottom: 30px;
    }

    .rating-total {
        opacity: .7;
    }
}

.rating-stars {
    margin: 10px auto;
    text-align: center;

    cursor: default;

    .rating-select {
        font-size: 16px;
    }

    select {
        display: none;
    }

    &.star-left {
        text-align: left;
    }

    .br-theme-fontawesome-stars .br-widget {
        height: 20px;
    }

    .br-theme-fontawesome-stars .br-readonly a {
        font-size: 16px;
        margin: 0 2px;
    }

    .br-theme-fontawesome-stars .br-widget a.br-selected:after,
    .br-theme-fontawesome-stars .br-widget a.br-active:after{
        color: #f8e81c;
    }
}

.graph-color {
    &-5 {
        background-color: #8BC34A;
        width: 100%;
    }
    &-4 {
        background-color: #B8E986;
        width: 20%;
    }
    &-3 {
        background-color: #F8E81C;
        width: 10%;
    }
    &-2 {
        background-color: #F6A623;
        width: 10%;
    }
    &-1 {
        background-color: #EF5350;
        width: 5%;
    }
}

.rating-graph {
    width: 100%;
    color: #9B9B9B;

    .graph-item {
        display: flex;
        align-items: center;
    }

    .graph-label {
        margin-right: 10px;
        font-size: 10px;
        width: 35px;

        .star-item {
            font-size: 16px;
            margin-right: 2px;
        }
    }

    .graph-result {
        position: relative;
        margin: 5px 0;
        width: 100%;

        .graph-value {
            position: relative;
            z-index: 2;
            color: #111;
            width: 100%;
            padding: 2px 5px;
            font-size: 10px;
            text-align: left;
        }

        .graph-progress {
            position: absolute;
            left: 0;
            top: 0;

            height: 100%;
        }
    }
}

.comments-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .comment-title {
        font-size: 16px;
        line-height: 50px;
        margin-right: 40px;
    }
}

.comments-content {
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
}

.comment-item {
    position: relative;
    width: 50%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid transparent;

    transition: all .2s;

    &:hover {
        border-color: #eee;
    }

    .comment-user {
        color: #2B425B;
        font-size: 16px;
    }

    .comment-date {
        text-transform: lowercase;
        color: #758697;
        font-size: 13px;
    }

    .comment-rating {
        text-align: left;
    }

    .comment {
        position: relative;

        .comment-title {
            color: #2B425B;
            font-size: 18px;
            margin-bottom: 10px;
        }
        .comment-content {
            color: #9b9b9b;
            font-size: 14px;
        }
    }
}

.load-more {
    cursor: pointer;
    color: #9b9b9b;
    font-size: 14px;
    padding: 10px;

    text-align: center;

    transition: all .2s;

    &:hover {
        background-color: #f1f1f1;
    }
}

.label-info {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px 10px;
    border: 1px solid #8BC34A;
    background-color: #fff;
    color: #8BC34A;
}

@media screen and (max-width: 768px) {
    .comment-item {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .btn-big{
        font-size: 14px;
    }
    .rating-stars .star-item {
        margin: 0;
    }

    .rating-header .right-side {
        margin: 10px;
    }

    .comments-header {
        display: block;
        text-align: center;
    }

    .comments-header .comment-title {
        margin-right: 0; 
    }
}

.rating-select-one {
    .br-wrapper {
        cursor: pointer;

        .br-widget a.br-active:after, .br-widget a.br-selected:after {
            color: #d2d2d2;
        }

        &.active, &:hover {
            .br-widget a.br-selected:after {
                color: #f8e81c;
            }
        }
    }

    a {
        pointer-events: none;
    }
}

.rating-stars.required-error > .control-label:after {
    content: "*Selecione uma avaliação";
    color: red;
    margin-left: 10px;
}

.comment-answer-holder
{
    padding-left: 20px;
    border-left: 1px solid #e5e5e5;

    > .comment-answer-text
    {
        display: block;
        color: #9b9b9b;
    }
    
    > .comment-answer-date
    {
        margin: 15px 0 0;
        font-weight: 600;
        text-align: right;
        display: block;
        color: #9b9b9b;
    }
    
    @media screen
    {
        @media (min-width: 768px)
        {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            > .comment-answer-text
            {
                flex: 1 1 100%;
                max-width: 100%;
            }

            > .comment-answer-date
            {
                margin: 0;
                flex: 0 0 120px;
                max-width: 120px;
                text-align:right;
            }
        }
    }
}

.comment
{
    .title-answer-rating
    {
        margin: 15px 0 5px;
        font-weight: 700;
        display: block;
        color: #2B425B;
    }
}