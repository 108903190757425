.training-category
{
	.category-title
	{
		margin-bottom: 42px;

		font-size: 18px;
		line-height: 24px;
		color: $second-color;
		font-weight: 400;

		.icon
		{
			background-size: contain;
			background-position: center left;
			background-repeat: no-repeat;
			width: 39px;
			height: 45px;
			display: inline-block;
			position: relative;
			top: 4px;
		}
	}
}