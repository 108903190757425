@import "settings";
@import "solotica";

/**
 * Mixins
 */
@import "mixins";
@import "mixins/screen";
@import "mixins/palette";

@import "generic";
@import "grid";
@import "typography";
@import "icons";

@import "base";

/**
 * Components
 */
@import "components/login";
@import "components/header";
@import "components/menu";
@import "components/hamburger";
@import "components/search";
@import "components/logo";
@import "components/buttons";
@import "components/panel";
@import "components/category";
@import "components/card";
@import "components/footer";
@import "components/social";
@import "components/rating";
@import "components/faq";
@import "components/course";
@import "components/banners";
@import "components/preview";

@import "track/index";