@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?wrv51o');
  src:  url('../../fonts/icomoon.eot?wrv51o#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?wrv51o') format('truetype'),
    url('../../fonts/icomoon.woff?wrv51o') format('woff'),
    url('../../fonts/icomoon.svg?wrv51o#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-objective:before {
  content: "\e914";
}
.icon-question:before {
  content: "\e900";
}
.icon-map-pin-route:before {
  content: "\e901";
}
.icon-info-icon:before {
  content: "\e902";
}
.icon-arrow-chev-right:before {
  content: "\e903";
}
.icon-search-track:before {
  content: "\e904";
}
.icon-plus-track:before {
  content: "\e905";
}
.icon-arrow-down-track:before {
  content: "\e906";
}
.icon-close-track:before {
  content: "\e907";
}
.icon-audio:before {
  content: "\e908";
}
.icon-next-podcast:before {
  content: "\e909";
}
.icon-play-bar:before {
  content: "\e90a";
}
.icon-play:before {
  content: "\e90b";
}
.icon-prev-podcast:before {
  content: "\e90c";
}
.icon-volume:before {
  content: "\e90d";
}
.icon-star-full:before {
  content: "\e90e";
}
.icon-star-empty:before {
  content: "\e90f";
}
.icon-pause-bar:before {
  content: "\e910";
}
.icon-pause:before {
  content: "\e911";
}
.icon-compare_arrows:before {
  content: "\e912";
}
.icon-arrow_upward:before {
  content: "\e913";
}
